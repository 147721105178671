import {getConfig} from "utils";
import {useCallback} from "react";
import {showAlert} from "@redux/slices";
import {close} from "@redux/slices/modal";
import {useAppSelector} from "./useAppSelector";
import {useAppDispatch} from "./useAppDispatch";
import {update} from "@redux/slices/config/thunks";

export const useToggleLive = () => {
  const dispatch = useAppDispatch();
  const {configs} = useAppSelector("config");
  const {lotteries} = useAppSelector("lottery");
  const config = getConfig(configs, "on_live");
  const inProgress = lotteries.find(({status}) => status === 1);

  const toggleLive = useCallback(
    (nextValue: string) => {
      if (typeof inProgress === "undefined") {
        dispatch(close());
        dispatch(
          showAlert({
            type: "error",
            title: "Error en cambio a Live",
            message: "No puedes pasar a LIVE si no hay un sorteo en progreso",
          })
        );
        return;
      }
      dispatch(
        update({
          ...config,
          value: nextValue,
          alertTitle: `Modo EN VIVO ${
            nextValue === "1" ? "activado" : "desactivado"
          }`,
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [configs, inProgress]
  );

  return {toggleLive};
};
