import {api} from "api";
import {State} from "./types";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const get = createAsyncThunk(
  "testimonials/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<State["testimonials"]>(
        "/lottery/getTestimonial?limit=10&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
