import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 0.4rem 0.7rem;
  justify-content: center;
  background-color: ${({theme}) => theme["primary"]};
`;
