import * as Yup from "yup";
import {LoginPayload} from "interfaces";

export const initialValues: LoginPayload = {
  email: "",
  password: "",
};

export const loginSchema = Yup.object().shape({
  password: Yup.string().required("Este campo es requerido"),
  email: Yup.string()
    .email("Debe ser un correo válido")
    .matches(/@[^.]*\./, "Debe ser un correo válido")
    .required("Este campo es requerido"),
});
