import {useEffect} from "react";
import {Container} from "./styled";
import {Check, X} from "react-feather";
import {hideAlert} from "@redux/slices";
import {useTheme} from "styled-components";
import {AnimatePresence} from "framer-motion";
import {useAppDispatch, useAppSelector} from "hooks";
import {Column, IconButton, Typography} from "components";

interface AlertProps {}

export const Alert: React.FC<AlertProps> = () => {
  const dispatch = useAppDispatch();
  const {white, heart, success} = useTheme();
  const {shown, title, message, type, durationMS} = useAppSelector("alert");
  const isError = type === "error";
  const Icon = isError ? X : Check;

  useEffect(() => {
    if (!shown) return;
    setTimeout(() => {
      dispatch(hideAlert());
    }, durationMS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shown, durationMS]);

  return (
    <AnimatePresence>
      {shown && (
        <Container
          exit={{transform: "translateY(12rem)"}}
          initial={{transform: "translateY(6rem)"}}
          animate={{transform: "translateY(0rem)"}}
        >
          <IconButton
            size={38}
            backgroundColor={isError ? heart : success}
            icon={<Icon strokeWidth={3} color={white} />}
          />
          <Column gap={0}>
            {title && (
              <Typography
                fontSize={17}
                fontFamily={message ? "Poppins-SemiBold" : "Poppins"}
              >
                {title}
              </Typography>
            )}
            {message && (
              <Typography fontSize={title ? 15 : 17}>{message}</Typography>
            )}
          </Column>
        </Container>
      )}
    </AnimatePresence>
  );
};
