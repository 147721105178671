import {useAppSelector} from "hooks";
import {ButtonStyled} from "./styled";
import {getSpinnerColors} from "./helper";
import {ButtonLoaderId} from "interfaces";
import {useTheme} from "styled-components";
import {colors, pickButtonColor} from "styles";
import {ColorRing} from "react-loader-spinner";
import {ButtonHTMLAttributes, Fragment} from "react";
import {Row, Typography, TypographyProps} from "../..";

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label: string;
  color?: string;
  leftIcon?: JSX.Element;
  rightIcon?: JSX.Element;
  loaderId?: ButtonLoaderId;
  typographyProps?: Omit<TypographyProps, "children">;
}

export const Button: React.FC<ButtonProps> = (props) => {
  const {
    label,
    loaderId,
    leftIcon,
    rightIcon,
    style = {},
    typographyProps,
    disabled = false,
    color = colors["white"],
    ...buttonProps
  } = props;
  const {buttonLoaderIds} = useAppSelector("layout");
  const {isDark, primary, disabledButtonTextColor, white003} = useTheme();
  const buttonTextColor = disabled ? disabledButtonTextColor : color;
  const loading = buttonLoaderIds["includes"](loaderId as ButtonLoaderId);
  const loaderColor = getSpinnerColors(
    style && style["backgroundColor"] ? style["backgroundColor"] : primary
  );

  return (
    <ButtonStyled
      $darkMode={isDark}
      disabled={disabled || loading}
      $disabled={disabled || loading}
      style={{
        ...style,
        backgroundColor:
          disabled || loading
            ? white003
            : style["backgroundColor"] ?? pickButtonColor(disabled, isDark),
      }}
      children={
        <Row gap={5} justifyContent="center">
          {loading ? (
            <ColorRing
              width="40"
              height="40"
              visible={loading}
              colors={loaderColor}
            />
          ) : (
            <Fragment>
              {leftIcon && leftIcon}
              <Typography color={buttonTextColor} {...typographyProps}>
                {label}
              </Typography>
              {rightIcon && rightIcon}
            </Fragment>
          )}
        </Row>
      }
      {...buttonProps}
    />
  );
};
