import {get} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";

export const historylice = createSlice({
  name: "history",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["history"] = setRequestStatus("initial");
    });

    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["history"] = data;
      state["request"]["history"] = setRequestStatus("success");
    });

    builder.addCase(get.rejected, function (state) {
      state["request"]["history"] = setRequestStatus("error");
    });
  },
});

// export const {} = historylice["actions"];
export const {reducer: history} = historylice;
