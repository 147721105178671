import {api} from "api";
import {CreateSupportTicketPayload, SupportTicketModel} from "interfaces";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";
import {toggleButtonLoaderId} from "../layout";
import {thunkSuccess} from "@redux/thunk-success";
import {go} from "../navigation";

export const get = createAsyncThunk(
  "support/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<SupportTicketModel[]>(
        "/tickets/getAll?limit=1000&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getById = createAsyncThunk(
  "support/get-by-id",
  async (supportTicketId: string, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<SupportTicketModel>(
        `/tickets/get/${supportTicketId}`
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const addResponse = createAsyncThunk(
  "support/add-response",
  async (
    {ticketId, ...payload}: CreateSupportTicketPayload & {ticketId: string},
    {dispatch, rejectWithValue}
  ) => {
    dispatch(toggleButtonLoaderId("add-support-ticket-response"));
    try {
      const {data} = await api.Post<SupportTicketModel>(
        `/tickets/addResponse/${ticketId}`,
        payload
      );

      thunkSuccess(dispatch, {
        title: "Respuesta enviada con éxito",
        message: "Le avisaremos al usuario que has respondido a su ticket",
      });
      dispatch(go("/support"));
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("add-support-ticket-response"));
    }
  }
);
