import {UserModel} from "interfaces";

export const userInitialState: UserModel = {
  description: "",
  username: "",
  _id: "",
  email: "",
  name: "",
  credit: null,
  lastname: "",
  phone: "",
  birthday: "",
  address: "",
  role: 2,
  code: 0,
  referralCode: "",
  myReferrals: [],
  deleted: false,
  picture: "",
  createdAt: new Date(),
  updatedAt: new Date(),
  __v: 0,
};
