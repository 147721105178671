import moment from "moment";
import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Eye, Trash, RotateCcw} from "react-feather";
import {get, remove} from "@redux/slices/lottery/thunks";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {
  Table,
  Column,
  Status,
  Header,
  Confirm,
  Skeleton,
  HasAccess,
  Typography,
  IconButton,
  ComponentLoader,
} from "components";

interface LotteriesProps {}

export const Lotteries: React.FC<LotteriesProps> = () => {
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {isSuperAdmin} = useAppSelector("auth");
  const {lotteries, request} = useAppSelector("lottery");
  const {white, heart, container, blackWhite} = useTheme();

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column>
      <Header children="Historial de sorteos" icon={RotateCcw} />
      <ComponentLoader
        requestStatus={request["lotteries"]}
        skeleton={
          <Column width="100%">
            <Column width="100%">
              <Skeleton width="100%" height={40} />
              <Column gap={1} width="100%">
                <Skeleton count={8} width="100%" height={55} />
              </Column>
            </Column>
          </Column>
        }
      >
        <Container>
          <Table
            data={lotteries}
            columns="50px 240px 85px 125px 120px 120px 120px 120px 120px 100px 110px"
            head={[
              {label: "Nro."},
              {label: "Título"},
              {label: "Precio"},
              {label: "Disponibles"},
              {label: "Vendidos"},
              {label: "Creación"},
              {label: "Inicio"},
              {label: "Cierre"},
              {label: "Premios"},
              {label: "Estado"},
              {label: "Acciones"},
            ]}
            emptyUI={{
              icon: <RotateCcw size={40} color={blackWhite} />,
              message: "No hay registros de ningún sorteo creado",
            }}
          >
            {({
              _id,
              title,
              price,
              number,
              awards,
              status,
              end_date,
              createdAt,
              start_date,
              numbersArray,
              totalTicketsSold,
            }) => (
              <Table.Tr backgroundColor={container}>
                <Table.Td gap={6} overflow="hidden">
                  <Typography fontFamily="Poppins-Medium">#{number}</Typography>
                </Table.Td>
                <Table.Td>
                  <Typography className="line-clamp-1">{title}</Typography>
                </Table.Td>
                <Table.Td>
                  <Typography>${price}</Typography>
                </Table.Td>
                <Table.Td>
                  <Typography>{numbersArray["length"]} Tickets</Typography>
                </Table.Td>
                <Table.Td>
                  <Typography>{totalTicketsSold} Tickets</Typography>
                </Table.Td>
                <Table.Td>
                  <Typography>
                    {moment(createdAt).format("DD/MM/YYYY")}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography>
                    {moment(start_date).format("DD/MM/YYYY")}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography>
                    {moment(end_date).format("DD/MM/YYYY")}
                  </Typography>
                </Table.Td>
                <Table.Td>
                  <Typography>{awards["length"]} premios</Typography>
                </Table.Td>
                <Table.Td>
                  <Status status={status} />
                </Table.Td>
                <Table.Td gap={6}>
                  <IconButton
                    toolTip="Ver sorteo"
                    icon={<Eye color={white} />}
                    onClick={() => push(`/history/lottery/${_id}`)}
                  />
                  <HasAccess only={isSuperAdmin}>
                    <IconButton
                      toolTip="Eliminar"
                      loaderId="remove-lottery"
                      icon={<Trash color={heart} />}
                      toolTipStyle={{backgroundColor: heart}}
                      onClick={() => {
                        openModal(
                          <Confirm
                            title="¿Seguro que deseas eliminarlo?"
                            message="Ya no podrás revertir esta acción"
                            actions={[
                              {
                                label: "Eliminar",
                                loaderId: "remove-lottery",
                                leftIcon: <Trash color={white} />,
                                style: {backgroundColor: heart},
                                onClick: () => dispatch(remove(_id as string)),
                              },
                              {
                                label: "Cancelar",
                                onClick: () => closeModal(),
                                style: {backgroundColor: "#bcbcbcec"},
                              },
                            ]}
                          />
                        );
                      }}
                    />
                  </HasAccess>
                </Table.Td>
              </Table.Tr>
            )}
          </Table>
        </Container>
      </ComponentLoader>
    </Column>
  );
};
