import {State} from "./types";
import {setRequestStatus} from "@redux/request-status";

export const initialState: State = {
  cities: [],
  states: [],
  countries: [],
  request: {
    states: setRequestStatus("initial"),
    cities: setRequestStatus("initial"),
    countries: setRequestStatus("initial"),
  },
};
