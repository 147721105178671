import {Fragment} from "react/jsx-runtime";
import {Children, RequestStatus} from "interfaces";
import {EmptyUIState, EmptyUIStateProps} from "components/@system";

interface ComponentLoaderProps extends Children<any> {
  skeleton: JSX.Element;
  requestStatus: RequestStatus;
  emptyUI?: Omit<EmptyUIStateProps, "children">;
}

export const ComponentLoader: React.FC<ComponentLoaderProps> = ({
  emptyUI,
  children,
  skeleton,
  requestStatus,
}) => {
  const {success, error} = requestStatus;

  return success ? (
    typeof emptyUI !== "undefined" ? (
      <EmptyUIState {...emptyUI}>{children}</EmptyUIState>
    ) : (
      children
    )
  ) : error ? (
    <Fragment />
  ) : (
    skeleton
  );
};
