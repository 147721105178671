import {Formik} from "formik";
import {useEffect} from "react";
import {useTheme} from "styled-components";
import {configSchema} from "./form.schema";
import {Key, Plus, Settings, Trash} from "react-feather";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {get, remove, update} from "@redux/slices/config/thunks";
import {
  Row,
  Input,
  Button,
  Header,
  Column,
  Confirm,
  Skeleton,
  NewConfig,
  IconButton,
  Typography,
  ComponentLoader,
  SuperAdminRoute,
} from "components";

interface ConfigProps {}

export const Config: React.FC<ConfigProps> = () => {
  const {heart, white} = useTheme();
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {configs, request} = useAppSelector("config");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SuperAdminRoute>
      <Column>
        <Header
          children="Configuración"
          icon={Settings}
          actions={[
            {
              type: "icon-button",
              props: {
                icon: <Plus color={white} />,
                toolTip: "Crear nueva configuración",
                onClick: () => openModal(<NewConfig />),
              },
            },
          ]}
        />
        <ComponentLoader
          requestStatus={request["configs"]}
          skeleton={
            <Column width="60%" gap={25}>
              <Column width="100%">
                <Skeleton height={40} width="40%" />
                <Column width="100%" gap={3}>
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                </Column>
                <Skeleton height={40} width={120} />
              </Column>
              <Column width="100%">
                <Skeleton height={40} width="40%" />
                <Column width="100%" gap={3}>
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                </Column>
                <Skeleton height={40} width={120} />
              </Column>
              <Column width="100%">
                <Skeleton height={40} width="40%" />
                <Column width="100%" gap={3}>
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                  <Skeleton height={50} width="100%" />
                </Column>
                <Skeleton height={40} width={120} />
              </Column>
            </Column>
          }
        >
          <Column>
            {configs.map(({label, _id}, keyIndex, arr) => (
              <Formik
                key={_id}
                enableReinitialize
                initialValues={arr[keyIndex]}
                validationSchema={configSchema}
                onSubmit={(payload) => {
                  dispatch(update(payload));
                }}
              >
                {({
                  dirty,
                  errors,
                  values,
                  isValid,
                  touched,
                  handleBlur,
                  handleSubmit,
                  handleChange,
                }) => {
                  const isDisabled = !(dirty && isValid);
                  return (
                    <Column width="100%" key={_id}>
                      <Row>
                        <Typography fontFamily="Poppins-Medium" fontSize={16}>
                          #{keyIndex + 1} — {label}
                        </Typography>
                        <IconButton
                          icon={<Trash size={21} color={heart} />}
                          onClick={() => {
                            openModal(
                              <Confirm
                                title={`Eliminar ${label}`}
                                message="Presiona Eliminar para continuar. Una vez eliminado no se podrá recuperar y deberás crear una nueva en caso que desees ver esta configuración en la Aplicación"
                                actions={[
                                  {
                                    label: "Eliminar",
                                    loaderId: "remove-config",
                                    style: {backgroundColor: heart},
                                    onClick: () =>
                                      dispatch(remove(_id as string)),
                                  },
                                  {
                                    label: "Cancelar",
                                    onClick: () => closeModal(),
                                    style: {backgroundColor: "#bcbcbcec"},
                                  },
                                ]}
                              />
                            );
                          }}
                        />
                      </Row>
                      <Column gap={0} width="100%" borderRadius={5}>
                        <Input
                          name="label"
                          value={values["label"]}
                          touched={touched["label"]}
                          placeholder="Configuración"
                          onBlur={handleBlur("label")}
                          onChange={handleChange("label")}
                          helperErrorText={errors["label"]}
                        />
                        <Input
                          icon={Key}
                          name="key"
                          value={values["key"]}
                          touched={touched["key"]}
                          placeholder="Identificador"
                          onBlur={handleBlur("key")}
                          onChange={handleChange("key")}
                          helperErrorText={errors["key"]}
                        />
                        <Input
                          name="value"
                          type="number"
                          placeholder="Valor"
                          value={values["value"]}
                          touched={touched["value"]}
                          onBlur={handleBlur("value")}
                          onChange={handleChange("value")}
                          helperErrorText={errors["value"]}
                        />
                      </Column>
                      <Button
                        label="Actualizar"
                        loaderId="new-config"
                        disabled={isDisabled}
                        onClick={() => handleSubmit()}
                      />
                    </Column>
                  );
                }}
              </Formik>
            ))}
          </Column>
        </ComponentLoader>
      </Column>
    </SuperAdminRoute>
  );
};
