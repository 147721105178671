import {storage} from "./storage";
import {APIResponse} from "interfaces";
import {thunkBadRequest} from "@redux/thunk-error";
import {ThunkDispatch, UnknownAction} from "@reduxjs/toolkit";

export async function postFormData<T>(
  uri: string,
  data: FormData,
  errorDispatch: ThunkDispatch<unknown, unknown, UnknownAction>,
  method: "POST" | "PUT" = "POST"
) {
  try {
    const response = await fetch(`${process.env.REACT_APP_PUBLIC_API}${uri}`, {
      method,
      body: data,
      redirect: "follow",
      headers: {
        Authorization: `Bearer ${storage["read"]("[auth-token]") || ""}`,
      },
    });
    return (await response.json()) as APIResponse<T>;
  } catch (error) {
    thunkBadRequest(errorDispatch, error);
  }
}
