import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(55px, 1fr));
`;

export const Ticket = styled.div`
  height: 55px;
  display: flex;
  position: relative;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: ${({theme}) => theme["container"]};
  cursor: pointer;

  &:hover {
    .user-hovered {
      opacity: 1;
    }
  }
`;

export const User = styled.div`
  right: 0;
  z-index: 2;
  opacity: 0;
  height: 25px;
  display: flex;
  bottom: -30px;
  padding: 0 1rem;
  width: min-content;
  position: absolute;
  border-radius: 1rem;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
  box-shadow: 0px 2px 3px 1px rgba(0, 0, 0, 0.1);
  background-color: ${({theme}) => theme["secondary"]};
`;
