import moment from "moment";
import {Formik} from "formik";
import {formatDate} from "utils";
import {useAppDispatch} from "hooks";
import {LotteryModel} from "interfaces";
import {Container, Grid} from "./styled";
import {Calendar, DollarSign} from "react-feather";
import {create, update} from "@redux/slices/lottery/thunks";
import {createLotterySchema, initialValues} from "./form.schema";
import {Input, Column, Button, Typography, Textarea} from "components";

interface NewLotteryProps {
  defaultValues?: LotteryModel;
}

export const NewLottery: React.FC<NewLotteryProps> = ({defaultValues}) => {
  const dispatch = useAppDispatch();
  const editable = typeof defaultValues !== "undefined";

  return (
    <Container>
      <Column>
        <Column width="100%" gap={0}>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            {editable ? "Editar" : "Nuevo"} sorteo
          </Typography>
        </Column>
        <Formik
          enableReinitialize
          validationSchema={createLotterySchema}
          initialValues={defaultValues ?? initialValues}
          onSubmit={(payload) => {
            payload["numbers"] = Number(payload["numbers"]);
            payload["price"] = Number(payload["price"]);
            if (editable) {
              dispatch(update({...defaultValues, ...payload}));
            } else {
              dispatch(create(payload));
            }
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
            setFieldValue,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={0} width="100%">
                <Input
                  autoFocus
                  name="title"
                  label="Nombre"
                  value={values["title"]}
                  touched={touched["title"]}
                  placeholder="Sorteo especial"
                  onBlur={handleBlur("title")}
                  onChange={handleChange("title")}
                  helperErrorText={errors["title"]}
                />
                <Textarea
                  name="description"
                  label="Descripción"
                  value={values["description"]}
                  touched={touched["description"]}
                  onBlur={handleBlur("description")}
                  onChange={handleChange("description")}
                  helperErrorText={errors["description"]}
                  placeholder="Participa y obten tus premios soñados"
                />
                <Input
                  icon={Calendar}
                  name="start_date"
                  type="datetime-local"
                  label="Fecha de inicio"
                  touched={touched["start_date"]}
                  onBlur={handleBlur("start_date")}
                  helperErrorText={errors["start_date"]}
                  value={formatDate(values["start_date"])}
                  onChange={({target}) =>
                    setFieldValue(
                      "start_date",
                      moment(target["value"]).format("YYYY-MM-DD[T]HH:mm:ss")
                    )
                  }
                />
                <Input
                  icon={Calendar}
                  name="end_date"
                  type="datetime-local"
                  label="Fecha de cierre de inscripciones"
                  touched={touched["end_date"]}
                  onBlur={handleBlur("end_date")}
                  helperErrorText={errors["end_date"]}
                  value={formatDate(values["end_date"])}
                  onChange={({target}) =>
                    setFieldValue(
                      "end_date",
                      moment(target["value"]).format("YYYY-MM-DD[T]HH:mm:ss")
                    )
                  }
                />
                <Input
                  icon={Calendar}
                  name="draw_date"
                  type="datetime-local"
                  touched={touched["draw_date"]}
                  onBlur={handleBlur("draw_date")}
                  helperErrorText={errors["draw_date"]}
                  value={formatDate(values["draw_date"])}
                  label="Fecha de cierre automático del sorteo"
                  onChange={({target}) =>
                    setFieldValue(
                      "draw_date",
                      moment(target["value"]).format("YYYY-MM-DD[T]HH:mm:ss")
                    )
                  }
                />
                <Grid>
                  <Input
                    type="number"
                    name="numbers"
                    placeholder="1000"
                    value={values["numbers"]}
                    touched={touched["numbers"]}
                    onBlur={handleBlur("numbers")}
                    onChange={handleChange("numbers")}
                    label="Tickets/Números disponibles"
                    helperErrorText={errors["numbers"]}
                  />
                  <Input
                    name="price"
                    type="number"
                    icon={DollarSign}
                    placeholder="$ (USD)"
                    label="Precio del ticket"
                    value={values["price"]}
                    touched={touched["price"]}
                    onBlur={handleBlur("price")}
                    onChange={handleChange("price")}
                    helperErrorText={errors["price"]}
                  />
                </Grid>
                <Column width="100%" alignItems="center" marginTop={18}>
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    loaderId="new-lottery"
                    style={{width: "100%"}}
                    onClick={() => handleSubmit()}
                    label={editable ? "Actualizar" : "Crear"}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};
