import {CSSProperties} from "react";
import {ColorRing, Style} from "react-loader-spinner";
import {useTheme} from "styled-components";

interface SpinnerLoaderProps {
  size?: number;
  style?: CSSProperties;
}

export const SpinnerLoader: React.FC<SpinnerLoaderProps> = ({
  size = 50,
  style,
}) => {
  const {secondary} = useTheme();
  return (
    <ColorRing
      width={size}
      height={size}
      wrapperStyle={style as Style}
      colors={[secondary, secondary, secondary, secondary, secondary]}
    />
  );
};
