import {motion} from "framer-motion";
import styled from "styled-components";

export const Container = styled(motion.div)`
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  z-index: 999999999;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.3);
`;

export const MainImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px;
`;

export const Grid = styled.div`
  width: 95%;
  display: grid;
  grid-gap: 1rem;
  margin: 0 auto;
  align-items: flex-start;
  grid-template-columns: 1fr;
  ${({theme}) => theme.breakpoints.up("sm")} {
    width: 650px;
    grid-template-columns: 80px auto;
  }
`;

export const Thumbnails = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({theme}) => theme.breakpoints.up("sm")} {
    gap: 1rem;
    flex-direction: column;
  }
`;

export const Thumbnail = styled.img`
  width: 80px;
  height: 80px;
  cursor: pointer;
  border-radius: 10px;
`;
