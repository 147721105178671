import {colors} from "styles";
import {Style} from "interfaces";
import {useOutside} from "hooks";
import {useTheme} from "styled-components";
import {AnimatePresence} from "framer-motion";
import {ChevronDown, Icon} from "react-feather";
import {Fragment, useEffect, useRef, useState} from "react";
import {Column, Row, Typography, Input, InputProps} from "../..";
import {Field, Container, DropdownItem, DropdownStyled} from "./styled";

export type DropdownOption = {
  icon?: Icon;
  label: string;
  value: string | number;
};

interface DropdownProps extends Style {
  inputProps?: InputProps;
  options: DropdownOption[];
  defaultValue?: string | number;
  onSelect: (option: DropdownOption) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  style,
  options,
  onSelect,
  inputProps,
  defaultValue,
}) => {
  const ref = useRef(null);
  const {primary, black05, white05, isDark, line, blackWhite} = useTheme();
  const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);
  const [selected, setSelected] = useState<DropdownOption>(
    {} as DropdownOption
  );

  const ActiveIcon = selected["icon"];

  useOutside(ref, () => {
    setDisplayDropdown(false);
  });

  useEffect(() => {
    if (defaultValue) {
      setSelected(
        options.filter((item) => item["value"] === defaultValue)?.[0] ??
          options[0]
      );
    } else {
      setSelected(options[0]);
    }
  }, [defaultValue, options]);

  return (
    <Container style={style} ref={ref}>
      <Column gap={5}>
        <Field
          onClick={() => setDisplayDropdown((open) => !open)}
          style={{
            border: `2px solid ${displayDropdown ? primary : line}`,
          }}
        >
          <Row
            gap={10}
            gridTemplateColumns="19px auto"
            display={ActiveIcon ? "grid" : "flex"}
          >
            {ActiveIcon && <ActiveIcon size={18} color={colors["white"]} />}
            <Typography className="line-clamp-1">
              {selected["label"]}
            </Typography>
          </Row>
          <ChevronDown
            color={isDark ? white05 : black05}
            strokeWidth={displayDropdown ? 1.8 : 1.5}
            style={{
              transition: "all ease .3s",
              transform: displayDropdown ? "rotate(180deg)" : "rotate(0)",
            }}
          />
        </Field>
        <AnimatePresence>
          {displayDropdown && (
            <Fragment>
              <DropdownStyled
                exit={{transform: "translateY(1rem)"}}
                initial={{transform: "translateY(1rem)"}}
                animate={{transform: "translateY(0rem)"}}
                children={
                  <Fragment>
                    {typeof inputProps !== "undefined" && (
                      <Input
                        style={{width: "100%"}}
                        {...inputProps}
                        onChange={(event) => {
                          const {onChange} = inputProps;
                          if (onChange) onChange(event);
                        }}
                      />
                    )}
                    {options.map(({icon: Icon, label, value}, key) => {
                      const highlighted = value === selected["value"];
                      return (
                        <DropdownItem
                          key={key}
                          $highlighted={highlighted}
                          onClick={() => {
                            onSelect(options[key]);
                            setSelected(options[key]);
                            setDisplayDropdown(false);
                          }}
                        >
                          <Row display="grid" gridTemplateColumns="25px auto">
                            {Icon && (
                              <Icon
                                size={20}
                                color={highlighted ? "white" : blackWhite}
                              />
                            )}
                            <Typography
                              color={highlighted ? "white" : blackWhite}
                            >
                              {label}
                            </Typography>
                          </Row>
                        </DropdownItem>
                      );
                    })}
                  </Fragment>
                }
              />
            </Fragment>
          )}
        </AnimatePresence>
      </Column>
    </Container>
  );
};
