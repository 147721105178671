import styled from "styled-components";

export const Container = styled.div`
  gap: 5px;
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
`;

export const SelectStyled = styled.select`
  width: 100%;
  appearance: none;
  outline: none;
  border: unset;
  padding: 0.8rem;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 5px;
  font-family: inherit;
  -moz-appearance: none;
  background-color: unset;
  border: 2px solid ${({theme}) => theme["line"]};
  background-color: ${({theme}) => theme["container"]};
  color: ${({theme}) => (theme["isDark"] ? theme["white"] : theme["black"])};
`;

export const Option = styled.option`
  font-family: inherit;
`;
