import * as Yup from "yup";
import {ReportWinnerPayload} from "interfaces";

export const initialValues: ReportWinnerPayload = {
  awardId: "",
  lotteryId: "",
  participantId: "",
};

export const createLotterySchema = Yup.object().shape({
  participantId: Yup.string().required("Este campo es obligatorio"),
  awardId: Yup.string().required("Este campo es obligatorio"),
});
