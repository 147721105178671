import {Formik} from "formik";
import {Container} from "./styled";
import {create} from "@redux/slices/lives/thunks";
import {Input, Column, Button, Typography} from "components";
import {changeToLiveSchema, initialValues} from "./form.schema";
import {useAppDispatch, useAppSelector, useToggleLive} from "hooks";

interface ChangeToLiveProps {
  nextOnLiveValue: string;
}

export const ChangeToLive: React.FC<ChangeToLiveProps> = ({
  nextOnLiveValue,
}) => {
  const dispatch = useAppDispatch();
  const {toggleLive} = useToggleLive();
  const {lotteries} = useAppSelector("lottery");

  return (
    <Container>
      <Column>
        <Column>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            Enlace del EN VIVO
          </Typography>
          <Typography>
            Para cambiar a modo: EN VIVO, se necesita el enlace que usarán los
            usuarios para ser redireccionados a la transmisión
          </Typography>
        </Column>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={changeToLiveSchema}
          onSubmit={async ({liveURL}) => {
            const activeLottery = lotteries[0];
            if (activeLottery) {
              await dispatch(
                create({
                  url: liveURL,
                  startdate: String(new Date()),
                  lotteryId: activeLottery["_id"] as string,
                })
              );
              toggleLive(nextOnLiveValue);
            }
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={5} width="100%">
                <Input
                  name="liveURL"
                  value={values["liveURL"]}
                  touched={touched["liveURL"]}
                  onBlur={handleBlur("liveURL")}
                  onChange={handleChange("liveURL")}
                  helperErrorText={errors["liveURL"]}
                  placeholder="https://www.youtube.com/enlace"
                />
                <Column width="100%" alignItems="center">
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    loaderId="new-config"
                    style={{width: "100%"}}
                    label="Cambiar a EN VIVO"
                    onClick={() => handleSubmit()}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};
