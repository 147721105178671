import {X} from "react-feather";
import {toggleBodyScroll} from "utils";
import {useEffect, useState} from "react";
import {useTheme} from "styled-components";
import {Absolute} from "components/@system";
import {IconButton} from "components/@button";
import {AnimatePresence} from "framer-motion";
import {closeMultimedia} from "@redux/slices";
import {useAppDispatch, useAppSelector} from "hooks";
import {Container, MainImage, Thumbnail, Thumbnails, Grid} from "./styled";

interface MultimediaProps {}

export const Multimedia: React.FC<MultimediaProps> = () => {
  const dispatch = useAppDispatch();
  const {white, white01, black05, secondary} = useTheme();
  const [main, setMain] = useState<string>("");
  const {shown, assets} = useAppSelector("multimedia");

  useEffect(() => {
    toggleBodyScroll(shown);
  }, [shown]);
  useEffect(() => {
    setMain(assets[0]);
  }, [assets]);

  return (
    <AnimatePresence>
      {shown && (
        <Container
          exit={{opacity: 0, transform: "translateY(2rem)"}}
          initial={{opacity: 0, transform: "translateY(1rem)"}}
          animate={{opacity: 1, transform: "translateY(0rem)"}}
        >
          <Grid>
            <Thumbnails>
              {assets.map((src, key) => (
                <Thumbnail
                  key={key}
                  src={src}
                  onClick={() => setMain(src)}
                  style={{
                    border: `2px solid ${
                      src === main ? secondary : "transparent"
                    }`,
                  }}
                />
              ))}
            </Thumbnails>
            <MainImage src={main} />
          </Grid>
          <Absolute top={10} right={10}>
            <IconButton
              size={45}
              backgroundColor={black05}
              icon={<X color={white} />}
              onClick={() => dispatch(closeMultimedia())}
            />
          </Absolute>
        </Container>
      )}
    </AnimatePresence>
  );
};
