import {ButtonLoaderId} from "interfaces";
import {initialState} from "./initial-state";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    toggleOverlap: function (state, action: PayloadAction<boolean>) {
      state["showOverlap"] = action["payload"];
    },
    toggleFullScreenLoader: function (state, action: PayloadAction<boolean>) {
      state["showFullScreenLoader"] = action["payload"];
    },
    toggleButtonLoaderId: function (
      state,
      action: PayloadAction<ButtonLoaderId>
    ) {
      const buttonId = action["payload"];
      if (state["buttonLoaderIds"].includes(action["payload"])) {
        state["buttonLoaderIds"] = state["buttonLoaderIds"].filter(
          (c) => c !== buttonId
        );
      } else {
        state["buttonLoaderIds"].push(buttonId);
      }
    },
  },
});

export const {reducer: layout} = layoutSlice;
export const {toggleOverlap, toggleButtonLoaderId, toggleFullScreenLoader} =
  layoutSlice["actions"];
