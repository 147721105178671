import styled from "styled-components";

export const Container = styled.div`
  width: 60px;
  height: 35px;
  cursor: pointer;
  position: relative;
  border-radius: 100px;
  background-color: ${({theme}) => theme["toggleBackground"]};
`;

export const Switch = styled.div<{$on: boolean}>`
  top: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  position: absolute;
  align-items: center;
  border-radius: 100px;
  justify-content: center;
  transition: all ease 0.5s;
  transform: translateY(-50%);
  background-color: ${({theme}) => theme["primary"]};
  left: ${(props) => (props.$on ? "45%" : "5%")};
`;
