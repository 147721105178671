import {Container} from "./styled";
import {LotteryModel} from "interfaces";
import {useTheme} from "styled-components";
import {Typography} from "components/@system";

interface StatusProps {
  status: LotteryModel["status"];
}

const map: Record<LotteryModel["status"], string> = {
  1: "En curso",
  2: "Finalizado",
};

export const Status: React.FC<StatusProps> = ({status}) => {
  const {white, primary, heart} = useTheme();
  return (
    <Container style={{backgroundColor: status === 1 ? primary : heart}}>
      <Typography color={white} fontSize={12}>
        {map[status]}
      </Typography>
    </Container>
  );
};
