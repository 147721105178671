import {get} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";

export const liveSlice = createSlice({
  name: "lives",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["lives"] = setRequestStatus("initial");
    });
    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["lives"] = data;
      state["request"]["lives"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["lives"] = setRequestStatus("error");
    });
  },
});

export const {reducer: lives} = liveSlice;
