import styled from "styled-components";

export const Container = styled.div`
  width: 95vw;
  padding: 2rem 1rem;
  border-radius: 10px;
  background-color: ${({theme}) => theme["container"]};

  ${({theme}) => theme.breakpoints.up("sm")} {
    width: 490px;
    padding: 2rem 1.5rem;
  }
`;
