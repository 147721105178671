import moment from "moment";
import {formatDate} from "./format-date";

export const dateLeft = (endDate: Date): string => {
  const duration = moment.duration(moment(formatDate(endDate)).diff(moment()));

  const days = duration.days();
  duration.subtract(days, "days");

  const hours = duration.hours();
  duration.subtract(hours, "hours");

  const minutes = duration.minutes();
  duration.subtract(minutes, "minutes");

  const seconds = duration.seconds();

  let displayDateLeft = `${days > 0 ? `${days} días ` : ""}${
    hours > 0 ? `${hours} horas ` : ""
  }${minutes > 0 ? `y ${minutes} minutos` : ""}`;

  if (days <= 0 && hours <= 0) {
    displayDateLeft = `${minutes} minutos`;
  }

  if (days <= 0 && hours <= 0 && minutes <= 0) {
    displayDateLeft = `${seconds} segundos`;
  }

  if (days <= 0 && hours <= 0 && minutes <= 0 && seconds <= 0) {
    displayDateLeft = `No esta activo`;
  }

  return displayDateLeft;
};
