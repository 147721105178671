import styled from "styled-components";
import {IconButton} from "components/@button";

export const Container = styled.div``;

export const Close = styled(IconButton)`
  top: 15px;
  right: 15px;
  position: absolute;
`;
