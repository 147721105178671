import * as Yup from "yup";
import {GiveCreditPayload} from "interfaces";

export const initialValues: GiveCreditPayload = {
  userId: "",
  tickets: 0,
};

export const giveCreditSchema = Yup.object().shape({
  userId: Yup.string().required("Este campo es obligatorio"),
  tickets: Yup.number()
    .required("Este campo es obligatorio")
    .min(1, "La cantidad de tickets debe ser mayor a 0"),
});
