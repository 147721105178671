import {Formik} from "formik";
import {Container} from "./styled";
import {useAppDispatch} from "hooks";
import {CountryModel} from "interfaces";
import {countrySchema, initialValues} from "./form.schema";
import {Input, Column, Button, Typography} from "components";
import {createCountry, updateCountry} from "@redux/slices/location/thunks";

interface CountryFormProps {
  defaultValues?: CountryModel;
}

export const CountryForm: React.FC<CountryFormProps> = ({defaultValues}) => {
  const dispatch = useAppDispatch();
  const editable = typeof defaultValues !== "undefined";
  const data = editable ? defaultValues : initialValues;

  return (
    <Container>
      <Column>
        <Column width="100%" gap={0}>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            Nuevo país
          </Typography>
        </Column>
        <Formik
          initialValues={data}
          validationSchema={countrySchema}
          onSubmit={(payload) => {
            if (editable) {
              dispatch(updateCountry({...defaultValues, ...payload}));
            } else {
              dispatch(createCountry(payload));
            }
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={0} width="100%">
                <Input
                  name="name"
                  label="Nombre"
                  value={values["name"]}
                  touched={touched["name"]}
                  placeholder="Venezuela"
                  onBlur={handleBlur("name")}
                  onChange={handleChange("name")}
                  helperErrorText={errors["name"]}
                />
                <Input
                  name="code"
                  label="Código"
                  placeholder="VE"
                  value={values["code"]}
                  touched={touched["code"]}
                  onBlur={handleBlur("code")}
                  onChange={handleChange("code")}
                  helperErrorText={errors["code"]}
                />
                <Input
                  name="prefix"
                  label="Prefijo"
                  placeholder="+58"
                  value={values["prefix"]}
                  touched={touched["prefix"]}
                  onBlur={handleBlur("prefix")}
                  onChange={handleChange("prefix")}
                  helperErrorText={errors["prefix"]}
                />
                <Column width="100%" alignItems="center" marginTop={5}>
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    style={{width: "100%"}}
                    onClick={() => handleSubmit()}
                    label={editable ? "Actualizar" : "Crear"}
                    loaderId={editable ? "update-country" : "create-country"}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};
