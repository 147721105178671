import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  background-color: ${({theme}) => theme["container"]};
  &:hover {
    .table-td {
      background-color: unset;
    }
  }
`;
