import {api} from "api";
import moment from "moment";
import {StatisticModel} from "interfaces";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const get = createAsyncThunk(
  "statistics/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Post<StatisticModel[]>("/statistics/charts", {
        startDate: "1997-05-08",
        endDate: moment().format("YYYY-MM-DD"),
      });
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
