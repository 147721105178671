import {X} from "react-feather";
import {Clickable} from "interfaces";
import {useTheme} from "styled-components";
import {Absolute, IconButton} from "components";

interface RemoveProps extends Clickable {}

export const Remove: React.FC<RemoveProps> = ({onClick}) => {
  const {white, black} = useTheme();
  return (
    <Absolute top={-5} right={-5}>
      <IconButton
        size={25}
        backgroundColor={white}
        icon={<X size={16} color={black} />}
        style={{boxShadow: "0px 2px 3px 1px rgb(0,0,0, .1)"}}
        onClick={onClick}
      />
    </Absolute>
  );
};
