import {api} from "api";
import {Formik} from "formik";
import {Container} from "./styled";
import {useAppDispatch} from "hooks";
import {buildSelectOptions} from "utils";
import {useEffect, useState} from "react";
import {reportWinner} from "@redux/slices/lottery/thunks";
import {Column, Button, Typography, Select} from "components";
import {createLotterySchema, initialValues} from "./form.schema";
import {AwardModel, LotteryModel, ParticipantModel} from "interfaces";

interface ReportWinnerProps {
  lottery: LotteryModel;
}

export const ReportWinner: React.FC<ReportWinnerProps> = ({lottery}) => {
  const {_id, number, title, awards} = lottery;
  const dispatch = useAppDispatch();
  const [participants, setParticipants] = useState<ParticipantModel[]>([]);

  const getParticipants = async () => {
    const {data} = await api.Get<ParticipantModel[]>(
      `/lottery/getPartcipants/${_id}`
    );
    setParticipants(data);
  };

  useEffect(() => {
    getParticipants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const awardDisplayOptions: (AwardModel & {displayLabel: string})[] =
    awards.map((obj) => ({
      ...obj,
      displayLabel: `#${obj["position"]} — ${obj["title"]}`,
    }));

  const participantDisplayOptions: (ParticipantModel & {
    displayLabel: string;
  })[] = participants.map((obj) => ({
    ...obj,
    displayLabel: `Ticket Jugado: #${obj["number"]} — ${obj["user"]["name"]}`,
  }));

  return (
    <Container>
      <Column>
        <Column width="100%" gap={0}>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            Reportar ganador en Sorteo #{number}
          </Typography>
          <Typography>{title}</Typography>
        </Column>
        <Formik
          validationSchema={createLotterySchema}
          initialValues={{...initialValues, lotteryId: _id as string}}
          onSubmit={(payload) => {
            dispatch(reportWinner(payload));
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={5} width="100%">
                <Select
                  label="Ganador"
                  name="participantId"
                  value={values["participantId"]}
                  touched={touched["participantId"]}
                  onBlur={handleBlur("participantId")}
                  onChange={handleChange("participantId")}
                  helperErrorText={errors["participantId"]}
                  options={buildSelectOptions(
                    participantDisplayOptions,
                    "Selecciona el participante ganador",
                    {
                      keyValue: "_id",
                      keyLabel: "displayLabel",
                    }
                  )}
                />
                <Select
                  name="awardId"
                  label="Premio obtenido (posición)"
                  value={values["awardId"]}
                  touched={touched["awardId"]}
                  onBlur={handleBlur("awardId")}
                  onChange={handleChange("awardId")}
                  helperErrorText={errors["awardId"]}
                  options={buildSelectOptions(
                    awardDisplayOptions,
                    "Selecciona lugar obtenido",
                    {
                      keyValue: "_id",
                      keyLabel: "displayLabel",
                    }
                  )}
                />
                <Column width="100%" alignItems="center" marginTop={18}>
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    loaderId="report-winner"
                    style={{width: "100%"}}
                    label="Reportar ganador"
                    onClick={() => handleSubmit()}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};
