import {Container} from "./styled";
import {CSSProperties} from "react";

interface LineProps extends CSSProperties {}

export const Line: React.FC<LineProps> = (props) => {
  const {
    height = "1px",
    width = "100%",
    margin = ".8rem 0",
    backgroundColor = "rgba(255, 255, 255, .1)",
  } = props;
  return (
    <Container
      style={{
        width,
        margin,
        height,
        backgroundColor,
        ...props,
      }}
    />
  );
};
