import {api} from "api";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";
import {CreateLivePayload, LiveModel} from "interfaces";

export const get = createAsyncThunk(
  "lives/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<LiveModel[]>("/live/getAll?limit=1000&position=0");
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const create = createAsyncThunk(
  "lives/create",
  async (payload: CreateLivePayload, {dispatch, rejectWithValue}) => {
    try {
      await api.Post<LiveModel>(`/live/insert`, payload);
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);
