import {Style} from "interfaces";
import {Container, Switch} from "./styled";
import {CSSProperties, ReactNode} from "react";

interface ToggleProps extends Style {
  on: boolean;
  icon?: ReactNode;
  onToggle?: () => void;
  switchStyle?: CSSProperties;
}

export const Toggle: React.FC<ToggleProps> = ({
  on,
  icon,
  style,
  onToggle,
  switchStyle,
}) => {
  return (
    <Container
      onClick={onToggle}
      children={<Switch $on={on} children={icon} style={switchStyle} />}
      style={{
        ...style,
      }}
    />
  );
};
