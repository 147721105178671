import {Typography} from "../..";
import {Icon} from "react-feather";
import {useTheme} from "styled-components";
import {CSSProperties, InputHTMLAttributes, useState} from "react";
import {
  Container,
  IconLabel,
  InputStyled,
  typographyErrorProps,
} from "./styled";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  icon?: Icon;
  label?: string;
  touched?: boolean;
  helperErrorText?: string;
  containerStyle?: CSSProperties;
}

export const Input: React.FC<InputProps> = (props) => {
  const {placeholderTextColor, blackWhite} = useTheme();
  const [tempValue, setTempValue] = useState<string>("");
  const {
    label,
    style,
    icon: Icon,
    containerStyle,
    touched = false,
    helperErrorText,
    ...inputProps
  } = props;
  const {type = "text", placeholder = "", name, onChange} = inputProps;

  return (
    <Container style={containerStyle}>
      {label && <Typography fontWeight="500">{label}</Typography>}
      <div style={{position: "relative"}}>
        <InputStyled
          id={name}
          type={type}
          placeholder={placeholder}
          style={{
            ...style,
            padding: Icon ? "0.8rem 0.8rem 0.8rem 45px" : "0.8rem",
          }}
          {...inputProps}
          onChange={(event) => {
            setTempValue(event["target"]["value"]);
            if (onChange) onChange(event);
          }}
        />
        {Icon && (
          <IconLabel htmlFor={name}>
            <Icon
              size={20}
              color={tempValue ? blackWhite : placeholderTextColor}
            />
          </IconLabel>
        )}
      </div>
      {helperErrorText && touched && (
        <Typography {...typographyErrorProps}>{helperErrorText}</Typography>
      )}
    </Container>
  );
};
