import {Style} from "interfaces";
import {useTheme} from "styled-components";
import {CSSProperties, Fragment} from "react";
import UISkeleton from "react-loading-skeleton";

interface SkeletonProps extends CSSProperties, Style {
  count?: number;
}

export const Skeleton = (props: SkeletonProps) => {
  const {skeletonBaseColor, skeletonHighlightColor} = useTheme();
  const {count = 1, style, ...CSSstyle} = props;
  const items = Array(count)
    .fill(null)
    .map((_, key) => key);
  return (
    <Fragment>
      {items.map((key) => (
        <div style={{...CSSstyle, ...style}} key={key}>
          <UISkeleton
            width="100%"
            height="100%"
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
      ))}
    </Fragment>
  );
};
