import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
`;

export const Statistic = styled.div`
  display: flex;
  border-radius: 5px;
  gap: 1rem;
  flex-direction: column;
  padding: 1rem 1rem 2rem;
  height: 180px;
  justify-content: space-between;
  background-color: ${({theme}) => theme["container"]};
`;

export const Png = styled.img`
  width: 30px;
  object-fit: contain;
`;
