import "chart.js/auto";
import {useEffect} from "react";
import {Container} from "./styled";
import {BarChart} from "react-feather";
import {Bar, Line} from "react-chartjs-2";
import {useTheme} from "styled-components";
import {get} from "@redux/slices/statistics/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Column,
  Header,
  Skeleton,
  Line as UILine,
  ComponentLoader,
} from "components";

interface StatisticsProps {}

export const Statistics: React.FC<StatisticsProps> = () => {
  const dispatch = useAppDispatch();
  const {primary, secondary, black} = useTheme();
  const {statistics, request} = useAppSelector("statistics");
  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column gap={0}>
      <Header children="Estadísticas" icon={BarChart} />
      <ComponentLoader
        requestStatus={request["statistics"]}
        skeleton={
          <Column gap={20} width="100%">
            <Skeleton count={3} width="100%" height={500} />
          </Column>
        }
      >
        <Container>
          {statistics.map(({labels, title, values}, key) => {
            const Chart = key % 2 === 0 ? Line : Bar;
            const colors = [primary, secondary, black];
            return (
              <Column key={key}>
                <Chart
                  style={{height: "200px"}}
                  data={{
                    labels,
                    datasets: [
                      {
                        label: title,
                        data: values,
                        borderWidth: 1,
                        borderColor: colors[key],
                        backgroundColor: colors[key],
                      },
                    ],
                  }}
                />
                <UILine margin="2rem 0" />
              </Column>
            );
          })}
        </Container>
      </ComponentLoader>
    </Column>
  );
};
