import {useEffect} from "react";
import {useTheme} from "styled-components";
import {Award, TrendingUp} from "react-feather";
import {get} from "@redux/slices/ranking/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Table,
  Image,
  Column,
  Header,
  Skeleton,
  Typography,
  ComponentLoader,
} from "components";

interface RankingProps {}

export const Ranking: React.FC<RankingProps> = () => {
  const {container} = useTheme();
  const dispatch = useAppDispatch();
  const {request, ranking} = useAppSelector("ranking");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column gap={0}>
      <Header children="Tabla de posiciones" icon={Award} />
      <ComponentLoader
        requestStatus={request["ranking"]}
        skeleton={
          <Column gap={1} width="100%">
            <Skeleton count={14} width="100%" height={50} />
          </Column>
        }
      >
        <Table
          data={ranking}
          columns="110px 200px 350px 140px 150px"
          header={
            <Column gap={0} marginBottom={10}>
              <Typography>
                Los usuarios con más premios ganados en todos los sorteos
                realizados
              </Typography>
            </Column>
          }
          emptyUI={{
            message: "No hay usuarios registrados aún",
            icon: <TrendingUp size={40} />,
          }}
          head={[
            {label: "Posición"},
            {label: "Nombre"},
            {label: "Correo"},
            {label: "Teléfono"},
            {label: "Sort. Ganados"},
          ]}
        >
          {({email, name, picture, phone, lotteries}, key) => (
            <Table.Tr cursor="pointer" backgroundColor={container}>
              <Table.Td gap={6} overflow="hidden">
                <Typography fontFamily="Poppins-Light" fontSize={14}>
                  #{key + 1}
                </Typography>
              </Table.Td>
              <Table.Td gap={6} overflow="hidden">
                <Image
                  width={25}
                  height={25}
                  src={picture}
                  objectFit="cover"
                  borderRadius={100}
                />
                <Typography fontFamily="Poppins-Light" fontSize={14}>
                  {name}
                </Typography>
              </Table.Td>
              <Table.Td>
                <Typography fontFamily="Poppins-Light" fontSize={16}>
                  {email}
                </Typography>
              </Table.Td>
              <Table.Td>
                <Typography fontFamily="Poppins-Light" fontSize={16}>
                  {phone}
                </Typography>
              </Table.Td>
              <Table.Td>
                <Typography fontFamily="Poppins-Light" fontSize={16}>
                  {lotteries ? lotteries["won"] : 0}
                </Typography>
              </Table.Td>
            </Table.Tr>
          )}
        </Table>
      </ComponentLoader>
    </Column>
  );
};
