import styled from "styled-components";

export const SIDEBAR_WIDTH = "263px";

export const Container = styled.main`
  display: grid;
  height: 100vh;
  background-color: #030303;
  grid-template-columns: ${SIDEBAR_WIDTH} auto;
`;

export const Main = styled.section`
  display: grid;
  grid-template-rows: 60px calc(100vh - 60px);
`;

export const Body = styled.div`
  padding: 1rem;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${({theme}) => theme["line"]};
  }
`;
