import {ReactNode} from "react";
import {Row, Typography} from "components";
import {ChevronDown, Hash} from "react-feather";
import {
  AccordionItemProps,
  AccordionItem as Item,
} from "@szhsin/react-accordion";

export type CustomAccordionItemProps = AccordionItemProps & {
  tag?: string;
  header: ReactNode;
  itemIndex: number;
  activeIndex: number | undefined;
  onHeaderClick: (item: CustomAccordionItemProps) => void;
};

export const CustomAccordionItem: React.FC<CustomAccordionItemProps> = (
  props
) => {
  const {header, tag, onHeaderClick, itemIndex, activeIndex, ...rest} = props;
  const actived = itemIndex === activeIndex;
  return (
    <Item
      {...rest}
      onClick={() => onHeaderClick(props)}
      header={
        <Row width="100%">
          <Row>
            <Typography fontFamily="Poppins-Medium" fontSize={18}>
              {header}
            </Typography>
            {actived && tag && <Hash size={20} className="hash" />}
          </Row>
          <ChevronDown className="chevron-down" />
        </Row>
      }
    />
  );
};
