import {Formik} from "formik";
import {Container} from "./styled";
import {StateModel} from "interfaces";
import {buildSelectOptions} from "utils";
import {useAppDispatch, useAppSelector} from "hooks";
import {stateSchema, initialValues} from "./form.schema";
import {Input, Column, Button, Typography, Select} from "components";
import {createState, updateState} from "@redux/slices/location/thunks";

interface StateFormProps {
  defaultValues?: StateModel;
}

export const StateForm: React.FC<StateFormProps> = ({defaultValues}) => {
  const dispatch = useAppDispatch();
  const {countries} = useAppSelector("location");
  const editable = typeof defaultValues !== "undefined";
  const data = editable ? defaultValues : initialValues;

  return (
    <Container>
      <Column>
        <Column width="100%" gap={0}>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            Nuevo Estado
          </Typography>
        </Column>
        <Formik
          initialValues={data}
          validationSchema={stateSchema}
          onSubmit={(payload) => {
            if (editable) {
              dispatch(updateState({...defaultValues, ...payload}));
            } else {
              dispatch(createState(payload));
            }
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={0} width="100%">
                <Select
                  name="countryId"
                  value={values["countryId"]}
                  touched={touched["countryId"]}
                  onBlur={handleBlur("countryId")}
                  onChange={handleChange("countryId")}
                  helperErrorText={errors["countryId"]}
                  options={buildSelectOptions(countries, "Selecciona un país", {
                    keyValue: "_id",
                    keyLabel: "name",
                  })}
                />
                <Input
                  name="name"
                  label="Nombre"
                  value={values["name"]}
                  touched={touched["name"]}
                  placeholder="Lara"
                  onBlur={handleBlur("name")}
                  onChange={handleChange("name")}
                  helperErrorText={errors["name"]}
                />
                <Input
                  name="code"
                  label="Código"
                  placeholder="LA"
                  value={values["code"]}
                  touched={touched["code"]}
                  onBlur={handleBlur("code")}
                  onChange={handleChange("code")}
                  helperErrorText={errors["code"]}
                />
                <Column width="100%" alignItems="center" marginTop={5}>
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    style={{width: "100%"}}
                    onClick={() => handleSubmit()}
                    label={editable ? "Actualizar" : "Crear"}
                    loaderId={editable ? "update-state" : "create-state"}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};
