import {get} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";

export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["statistics"] = setRequestStatus("initial");
    });
    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["statistics"] = data;
      state["request"]["statistics"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["statistics"] = setRequestStatus("error");
    });
  },
});

export const {reducer: statistics} = statisticsSlice;
