import {SupportTicketModel} from "interfaces";

export const supportTicketInitialState: SupportTicketModel = {
  _id: "",
  name: "",
  email: "",
  phone: "",
  title: "",
  status: 1,
  userId: "",
  lastname: "",
  responses: [],
  description: "",
  statusString: "",
};
