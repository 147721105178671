import {storage} from "utils";
import {State} from "./types";
import {UserModel} from "interfaces";
import {userInitialState} from "@redux/initial-states";

const LOCAL_USER = storage["read"]<UserModel>("[user]");

export const initialState: State = {
  isLogged: !!storage["read"]("[user]"),
  user: LOCAL_USER || userInitialState,
  isSuperAdmin: LOCAL_USER ? LOCAL_USER["role"] === 1 : false,
};
