import {useState} from "react";
import {Container} from "./styled";
import {useAppDispatch, useAppSelector} from "hooks";
import {LotteryModel} from "interfaces";
import {insertMedia} from "@redux/slices/lottery/thunks";
import {
  Button,
  Column,
  HasAccess,
  Multimedia as UIMultimedia,
} from "components";

interface MultimediaProps {
  lottery: LotteryModel;
}

export const Multimedia: React.FC<MultimediaProps> = ({lottery}) => {
  const {media} = lottery;
  const {isSuperAdmin} = useAppSelector("auth");
  const dispatch = useAppDispatch();
  const [files, setFiles] = useState<File[]>([]);
  const [disabled, setDisabled] = useState<boolean>(true);

  return (
    <Container>
      <Column width="100%">
        <UIMultimedia
          media={media}
          multiple={false}
          extension="video"
          onNewMedia={(files) => {
            setFiles(files);
            setDisabled(!files["length"]);
          }}
        />
        <HasAccess only={isSuperAdmin}>
          <Button
            disabled={disabled}
            loaderId="insert-media"
            label="Guardar cambios"
            onClick={() => {
              const formData = new FormData();
              files.forEach((file) => {
                formData.append("files", file);
              });
              dispatch(insertMedia(formData));
            }}
          />
        </HasAccess>
      </Column>
    </Container>
  );
};
