import {useOutside} from "hooks";
import {Children} from "interfaces";
import {useLocation} from "react-router-dom";
import {AnimatePresence} from "framer-motion";
import {Container, List, ListItem} from "./styled";
import {Typography, TypographyProps} from "components";
import {CSSProperties, useCallback, useEffect, useRef, useState} from "react";

type OptionItem = {
  label: string;
  display?: boolean;
  icon?: JSX.Element;
  onClick: () => void;
  style?: CSSProperties;
  typographyProps?: Omit<TypographyProps, "children">;
};

export interface OptionsProps
  extends Children<(props: {toggle: () => void}) => JSX.Element> {
  title?: JSX.Element;
  options: OptionItem[];
  listStyle?: CSSProperties;
  closeOnPathnameChange?: boolean;
}

export const Options: React.FC<OptionsProps> = ({
  title,
  options,
  children,
  listStyle,
  closeOnPathnameChange = true,
}) => {
  const ref = useRef(null);
  const {pathname} = useLocation();
  const [listOpened, setListOpened] = useState<boolean>(false);

  useOutside(ref, () => {
    setListOpened(false);
  });

  const toggle = useCallback(() => {
    setListOpened((prev) => !prev);
  }, []);

  useEffect(() => {
    if (closeOnPathnameChange) setListOpened(false);
  }, [pathname, closeOnPathnameChange]);

  return (
    <Container>
      {children({toggle})}
      <AnimatePresence>
        {listOpened && (
          <List
            ref={ref}
            style={{...listStyle}}
            animate={{transform: "translateY(0)", opacity: 1}}
            exit={{transform: "translateY(2.5rem)", opacity: 0}}
            initial={{transform: "translateY(2.5rem)", opacity: 0}}
          >
            {title && title}
            {options.map(
              (
                {icon, label, style, typographyProps, display = true, onClick},
                key
              ) =>
                display ? (
                  <ListItem
                    key={key}
                    onClick={() => {
                      onClick();
                      setListOpened(false);
                    }}
                    style={{
                      gridTemplateColumns: icon ? "35px auto" : "1fr",
                      ...style,
                    }}
                  >
                    {icon}
                    <Typography {...typographyProps}>{label}</Typography>
                  </ListItem>
                ) : null
            )}
          </List>
        )}
      </AnimatePresence>
    </Container>
  );
};
