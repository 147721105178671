import {Children} from "interfaces";
import {Fragment} from "react/jsx-runtime";

interface HasAccessProps extends Children<any> {
  only: boolean;
}

export const HasAccess: React.FC<HasAccessProps> = ({only, children}) => {
  return only ? children : <Fragment />;
};
