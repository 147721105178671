import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Statistics, Tickets} from "pages/Lottery/lib";
import {AlertCircle, Eye, Upload} from "react-feather";
import {closeLottery, get} from "@redux/slices/lottery/thunks";
import {useAppDispatch, useAppSelector, useModal, useToggleLive} from "hooks";
import {EmojiEventsOutlined, HourglassEmptyRounded} from "@mui/icons-material";
import {Container as StatisticsSkeletonGrid} from "pages/Lottery/lib/Statistics/styled";
import {
  Row,
  Column,
  Header,
  Confirm,
  Skeleton,
  Typography,
  ReportWinner,
  NewTestimonial,
  ComponentLoader,
} from "components";

interface HomeProps {}

export const Home: React.FC<HomeProps> = () => {
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const {white, heart} = useTheme();
  const {toggleLive} = useToggleLive();
  const {openModal, closeModal} = useModal();
  const {isSuperAdmin} = useAppSelector("auth");
  const {lotteries, request} = useAppSelector("lottery");
  const currentLotteries = lotteries.filter(({status}) => status === 1);

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ComponentLoader
        requestStatus={request["lotteries"]}
        emptyUI={{
          data: currentLotteries,
          style: {maxWidth: "500px", margin: "1rem auto"},
          icon: <HourglassEmptyRounded style={{color: white, fontSize: 50}} />,
          message: isSuperAdmin
            ? `Actualmente no hay sorteos En Curso. Tus usuarios están esperando un nuevo sorteo`
            : "No sorteo activo actualmente",
        }}
        skeleton={
          <Column width="100%">
            <Skeleton width="30%" height={45} borderRadius={100} />
            <Column width="100%">
              <Skeleton width={150} height={35} borderRadius={100} />
              <Row>
                <Skeleton
                  count={3}
                  width={150}
                  height={45}
                  overflow="hidden"
                  borderRadius={100}
                />
              </Row>
              <StatisticsSkeletonGrid>
                <Skeleton count={5} height={150} />
              </StatisticsSkeletonGrid>
              <Column width="100%" gap={1}>
                <Skeleton count={5} width="100%" height={50} />
              </Column>
            </Column>
          </Column>
        }
      >
        {currentLotteries.map(({title, number, _id, status}, key, list) => (
          <Column key={key}>
            <Header
              children={`Sorteo #${number} — ${title}`}
              actions={[
                {
                  type: "icon-button",
                  props: {
                    icon: <Eye style={{color: white}} />,
                    toolTip: "Ver detalles",
                    onClick: () => push(`/history/lottery/${_id}`),
                  },
                },
                {
                  type: "icon-button",
                  props: {
                    icon: <EmojiEventsOutlined style={{color: white}} />,
                    toolTip: "Reportar ganador",
                    onClick: () =>
                      openModal(<ReportWinner lottery={list[key]} />),
                  },
                },
                {
                  type: "icon-button",
                  props: {
                    icon: <Upload style={{color: white}} />,
                    toolTip: "Subir testimonio",
                    onClick: () =>
                      openModal(<NewTestimonial lottery={list[key]} />),
                  },
                },
                {
                  type: "icon-button",
                  props: {
                    backgroundColor: heart,
                    toolTip: "Finalizar sorteo",
                    icon: <AlertCircle color={white} size={20} />,
                    onClick: () =>
                      openModal(
                        <Confirm
                          title="¿Seguro que deseas finalizar el sorteo?"
                          message="Una vez finalizado ya no podrán comprar más tickets los usuarios"
                          actions={[
                            {
                              label: "Finalizar",
                              loaderId: "close-lottery",
                              style: {backgroundColor: heart},
                              onClick: () => {
                                dispatch(closeLottery(_id as string)).then(
                                  () => {
                                    toggleLive("0");
                                  }
                                );
                              },
                            },
                            {
                              label: "Cancelar",
                              onClick: () => closeModal(),
                              style: {backgroundColor: "#bcbcbcec"},
                            },
                          ]}
                        />
                      ),
                  },
                },
              ]}
            />
            <Statistics lottery={list[key]} />
            <Column width="100%" marginTop={10}>
              <Typography fontSize={18} fontFamily="Poppins-SemiBold">
                Tickets
              </Typography>
              <Tickets lottery={list[key]} />
            </Column>
          </Column>
        ))}
      </ComponentLoader>
    </Container>
  );
};
