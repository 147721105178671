export const colors = {
  dark: "#18191A",
  dark2: "#242526",
  gray: "#9A9A9A",
  light: "#F3F3F3",
  heart: "#E31B23",
  footer: "#212121",
  success: "#22bb33",
  primary: "#285C9E",
  textGray: "#ABABAB",
  secondary: "#418160",
  lightLine: "#E5E5E5",
  lightTitle: "#212121",
  buttonHover: "#1c5091",
  avatarBorderColor: "#fff",
  adminBackgroundMessage: "#71a500",
  darkLine: "rgba(255, 255, 255, 0.3)",
  darkTitle: "rgba(255, 255, 255, 0.6)",
  primaryTransparent: "rgba(40, 92, 158, .08)",
  lightSkeletonBaseColor: "#ebebeb",
  lightSkeletonHighlightColor: "#f5f5f5",
  darkSkeletonBaseColor: "rgba(255, 255, 255, 0.03)",
  darkSkeletonHighlightColor: "rgba(255, 255, 255, 0.05)",
  // temporary colors
  black: "#000",
  black09: "rgba(0, 0, 0, 0.9)",
  black08: "rgba(0, 0, 0, 0.8)",
  black07: "rgba(0, 0, 0, 0.7)",
  black06: "rgba(0, 0, 0, 0.6)",
  black05: "rgba(0, 0, 0, 0.5)",
  black04: "rgba(0, 0, 0, 0.4)",
  black03: "rgba(0, 0, 0, 0.3)",
  black02: "rgba(0, 0, 0, 0.2)",
  black01: "rgba(0, 0, 0, 0.1)",
  black006: "rgba(0, 0, 0, 0.06)",
  black005: "rgba(0, 0, 0, 0.05)",
  black004: "rgba(0, 0, 0, 0.04)",
  black003: "rgba(0, 0, 0, 0.03)",
  black001: "rgba(0, 0, 0, 0.01)",
  white: "#fff",
  white09: "rgba(255, 255, 255, 0.9)",
  white08: "rgba(255, 255, 255, 0.8)",
  white07: "rgba(255, 255, 255, 0.7)",
  white06: "rgba(255, 255, 255, 0.6)",
  white05: "rgba(255, 255, 255, 0.5)",
  white04: "rgba(255, 255, 255, 0.4)",
  white03: "rgba(255, 255, 255, 0.3)",
  white02: "rgba(255, 255, 255, 0.2)",
  white01: "rgba(255, 255, 255, 0.1)",
  white004: "rgba(255, 255, 255, 0.04)",
  white003: "rgba(255, 255, 255, 0.03)",
  white001: "rgba(255, 255, 255, 0.01)",
};
