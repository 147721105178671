import {Container} from "./styled";
import {Button, ButtonProps, Typography, Row, Column, Line} from "components";

interface ConfirmProps {
  title: string;
  message: string;
  actions: ButtonProps[];
}

export const Confirm: React.FC<ConfirmProps> = ({title, message, actions}) => {
  return (
    <Container>
      <Column gap={4} marginBottom={15}>
        <Typography fontSize={18} fontFamily="Poppins-Medium">
          {title}
        </Typography>
        <Typography>{message}</Typography>
      </Column>
      <Line />
      <Row justifyContent="flex-end">
        {actions.map((button, key) => (
          <Button {...button} key={key} />
        ))}
      </Row>
    </Container>
  );
};
