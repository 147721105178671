import {Form} from "./lib";
import moment from "moment";
import {Skeleton} from "./Skeleton";
import {ArrowLeft} from "react-feather";
import {Fragment, useEffect} from "react";
import {useTheme} from "styled-components";
import {resetSupportTicket} from "@redux/slices";
import {useAppDispatch, useAppSelector} from "hooks";
import {getById} from "@redux/slices/support/thunks";
import {LocalPoliceOutlined} from "@mui/icons-material";
import {useNavigate, useParams} from "react-router-dom";
import {
  Row,
  Line,
  Chip,
  Column,
  Typography,
  IconButton,
  ComponentLoader,
  SuperAdminRoute,
} from "components";

interface SupportTicketProps {}

export const SupportTicket: React.FC<SupportTicketProps> = () => {
  const push = useNavigate();
  const {white} = useTheme();
  const {ticketId} = useParams();
  const dispatch = useAppDispatch();
  const {ticket, request} = useAppSelector("support");
  const {
    name,
    title,
    phone,
    email,
    lastname,
    createdAt,
    responses,
    description,
  } = ticket;

  useEffect(() => {
    dispatch(getById(ticketId as string));
    return () => {
      dispatch(resetSupportTicket());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ticketId]);

  return (
    <SuperAdminRoute>
      <ComponentLoader
        requestStatus={request["ticket"]}
        skeleton={<Skeleton />}
      >
        <Column gap={10} maxWidth="850px" margin="0 auto">
          <Row marginBottom={10}>
            <IconButton
              onClick={() => push(-1)}
              icon={<ArrowLeft size={20} color={white} />}
            />
            <Typography fontSize={20} fontFamily="Poppins-Medium">
              Ticket de soporte
            </Typography>
          </Row>
          <Column>
            <Column gap={5}>
              <Typography fontSize={12}>
                {moment(createdAt).fromNow()}
              </Typography>
              <Column gap={0}>
                <Typography fontFamily="Poppins-Medium">
                  {name} {lastname}
                </Typography>
                <Typography>Teléfono: {phone}</Typography>
                <Typography>Correo: {email}</Typography>
              </Column>
            </Column>
            <Column gap={0}>
              <Typography fontFamily="Poppins-SemiBold">{title}</Typography>
              <Typography>{description}</Typography>
            </Column>
          </Column>
          <Line />
          {!responses["length"] ? (
            <Fragment />
          ) : (
            <Column width="100%" gap={5}>
              <Typography
                fontSize={16}
                marginBottom={10}
                fontFamily="Poppins-Medium"
              >
                ({responses["length"]}) Respuestas enviadas:
              </Typography>
              <Column gap={25}>
                {responses.map(({name, lastname, title, description}, key) => (
                  <Column key={key}>
                    <Row>
                      <Chip>
                        <Row>
                          <LocalPoliceOutlined
                            style={{color: white, fontSize: 18}}
                          />
                          <Typography fontSize={12} color={white}>
                            Administrador
                          </Typography>
                        </Row>
                      </Chip>
                      <Typography fontFamily="Poppins-SemiBold">
                        {name} {lastname}
                      </Typography>
                    </Row>
                    <Column gap={0}>
                      <Typography fontFamily="Poppins-Medium">
                        {title}
                      </Typography>
                      <Typography>{description}</Typography>
                    </Column>
                  </Column>
                ))}
              </Column>
              <Line />
            </Column>
          )}
          <Form />
        </Column>
      </ComponentLoader>
    </SuperAdminRoute>
  );
};
