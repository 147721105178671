import {api} from "api";
import {close} from "../modal";
import {go} from "../navigation";
import {postFormData} from "utils";
import {showAlert} from "../alert";
import {RootState} from "@redux/store";
import {toggleButtonLoaderId} from "../layout";
import {thunkSuccess} from "@redux/thunk-success";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";
import {
  APIResponse,
  LotteryModel,
  NewLotteryPayload,
  ReportWinnerPayload,
} from "interfaces";

export const get = createAsyncThunk(
  "lottery/get-all",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<LotteryModel[]>(
        "/lottery/getAll?limit=100&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getOne = createAsyncThunk(
  "lottery/get-one",
  async (lotteryId: string, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<LotteryModel>(`/lottery/get/${lotteryId}`);
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const create = createAsyncThunk(
  "lottery/create",
  async (payload: NewLotteryPayload, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("new-lottery"));
    try {
      const {data} = await api.Post<LotteryModel>("/lottery/create", payload);
      dispatch(close());
      dispatch(go(`/history/lottery/${data["_id"]}`));
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("new-lottery"));
    }
  }
);

export const update = createAsyncThunk(
  "lottery/update",
  async (payload: LotteryModel, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("new-lottery"));
    try {
      const {_id, ...body} = payload;
      await api.Put<LotteryModel>(`/lottery/update/${_id}`, body);
      dispatch(close());
      dispatch(getOne(payload["_id"]));
      dispatch(showAlert({title: "Sorteo actualizado", type: "success"}));
      dispatch(go(`/history/lottery/${_id}`));
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("new-lottery"));
    }
  }
);

export const reportWinner = createAsyncThunk(
  "lottery/report-winner",
  async (
    {lotteryId, participantId, awardId}: ReportWinnerPayload,
    {dispatch, rejectWithValue}
  ) => {
    dispatch(toggleButtonLoaderId("report-winner"));
    try {
      await api.Post<LotteryModel>(
        `/lottery/winner/${lotteryId}/${participantId}/${awardId}`
      );
      thunkSuccess(dispatch, {title: "Se ha reportado un ganador con éxito"});
      dispatch(get());
      dispatch(close());
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("report-winner"));
    }
  }
);

export const insertAward = createAsyncThunk(
  "lottery/insert-award",
  async (payload: FormData, {dispatch, rejectWithValue, getState}) => {
    const {_id} = (getState() as RootState)["lottery"]["lottery"];
    dispatch(toggleButtonLoaderId("insert-award"));
    try {
      const {data} = (await postFormData<LotteryModel>(
        `/lottery/insertAward/${_id}`,
        payload,
        dispatch
      )) as APIResponse<LotteryModel>;

      dispatch(close());
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("insert-award"));
    }
  }
);

export const removeAward = createAsyncThunk(
  "lottery/remove-award",
  async (
    {lotteryId, awardId}: {lotteryId: string; awardId: string},
    {dispatch, rejectWithValue}
  ) => {
    dispatch(toggleButtonLoaderId("remove-award"));
    try {
      const {data} = await api.Delete<LotteryModel>(
        `/lottery/removeAward/${lotteryId}/${awardId}`
      );

      thunkSuccess(dispatch, {title: "Premio eliminado con éxito"});
      dispatch(close());
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("remove-award"));
    }
  }
);

export const addTestimonial = createAsyncThunk(
  "lottery/add-testimonial",
  async (
    {data: formData, awardId}: {data: FormData; awardId: string},
    {dispatch, rejectWithValue, getState}
  ) => {
    const {_id} = (getState() as RootState)["lottery"]["lottery"];
    dispatch(toggleButtonLoaderId("add-testimonial"));
    try {
      const {data} = (await postFormData<LotteryModel>(
        `/lottery/addTestimonial/${_id}/${awardId}`,
        formData,
        dispatch
      )) as APIResponse<LotteryModel>;

      dispatch(close());
      thunkSuccess(dispatch, {
        title: "Testimonio agregado",
        message: "Ya puede verlo los usuarios",
      });
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("add-testimonial"));
    }
  }
);

export const insertMedia = createAsyncThunk(
  "lottery/insert-media",
  async (payload: FormData, {dispatch, rejectWithValue, getState}) => {
    const {_id} = (getState() as RootState)["lottery"]["lottery"];
    dispatch(toggleButtonLoaderId("insert-media"));
    try {
      const {data} = (await postFormData<LotteryModel>(
        `/lottery/insertMedia/${_id}`,
        payload,
        dispatch,
        "PUT"
      )) as APIResponse<LotteryModel>;

      window.location.reload();
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("insert-media"));
    }
  }
);

export const remove = createAsyncThunk(
  "lottery/remove",
  async (lotteryId: string, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("remove-lottery"));
    try {
      await api.Delete(`/lottery/delete/${lotteryId}`);
      dispatch(close());
      dispatch(get());
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("remove-lottery"));
    }
  }
);

export const closeLottery = createAsyncThunk(
  "lottery/close-lottery",
  async (lotteryId: string, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("close-lottery"));
    try {
      await api.Post(`/lottery/close/${lotteryId}`);
      dispatch(close());
      dispatch(get());
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("close-lottery"));
    }
  }
);
