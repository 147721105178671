import {FC, useEffect} from "react";
import {Skeleton} from "./Skeleton";
import {LotteryModel} from "interfaces";
import {resetLottery} from "@redux/slices";
import {useTheme} from "styled-components";
import {getOne} from "@redux/slices/lottery/thunks";
import {ArrowLeft, Edit, Upload} from "react-feather";
import {useNavigate, useParams} from "react-router-dom";
import {EmojiEventsOutlined} from "@mui/icons-material";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {Awards, Info, Multimedia, Statistics, Tickets, Winners} from "./lib";
import {
  Row,
  Line,
  Column,
  Accordion,
  HasAccess,
  Typography,
  IconButton,
  NewLottery,
  ReportWinner,
  NewTestimonial,
  ComponentLoader,
  AccordionItemProps,
} from "components";

export type SectionItem = Omit<AccordionItemProps, "content"> & {
  content: FC<{lottery: LotteryModel}>;
};

const items: SectionItem[] = [
  {
    tag: "multimedia",
    content: Multimedia,
    header: "Multimedia",
  },
  {
    tag: "winners",
    content: Winners,
    header: "Ganadores",
  },
  {
    tag: "awards",
    content: Awards,
    header: "Premios",
  },
  {
    tag: "tickets",
    content: Tickets,
    header: "Tickets",
  },
];

interface LotteryProps {}

export const Lottery: React.FC<LotteryProps> = () => {
  const push = useNavigate();
  const {white} = useTheme();
  const {openModal} = useModal();
  const {lotteryId} = useParams();
  const dispatch = useAppDispatch();
  const {isSuperAdmin} = useAppSelector("auth");
  const {lottery, request} = useAppSelector("lottery");
  const {title} = lottery;

  useEffect(() => {
    dispatch(getOne(lotteryId as string));
    return () => {
      dispatch(resetLottery());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lotteryId]);

  return (
    <ComponentLoader requestStatus={request["lottery"]} skeleton={<Skeleton />}>
      <Column gap={10} maxWidth="1350px" margin="0 auto">
        <Row marginBottom={10}>
          <IconButton
            onClick={() => push(-1)}
            icon={<ArrowLeft size={20} color={white} />}
          />
          <Typography fontSize={20} fontFamily="Poppins-Medium">
            Información de {title}
          </Typography>
          <HasAccess only={isSuperAdmin}>
            <IconButton
              size={40}
              toolTipPosition="down"
              toolTip="Editar sorteo"
              icon={<Edit size={20} color={white} />}
              onClick={() => openModal(<NewLottery defaultValues={lottery} />)}
            />
            <IconButton
              size={40}
              toolTipPosition="down"
              toolTip="Reportar ganador"
              icon={<EmojiEventsOutlined style={{color: white}} />}
              onClick={() => openModal(<ReportWinner lottery={lottery} />)}
            />
          </HasAccess>
          <IconButton
            size={40}
            toolTipPosition="down"
            toolTip="Subir testimonio"
            icon={<Upload style={{color: white}} />}
            onClick={() => openModal(<NewTestimonial lottery={lottery} />)}
          />
        </Row>
        <Column width="100%" gap={35}>
          <Statistics lottery={lottery} />
          <Info lottery={lottery} />
        </Column>
        <Line marginBottom={0} />
        <Accordion
          items={items.map(({content: Component, ...data}) => ({
            ...data,
            content: <Component lottery={lottery} />,
          }))}
        />
      </Column>
    </ComponentLoader>
  );
};
