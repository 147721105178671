import * as Yup from "yup";
import {NewLotteryPayload} from "interfaces";

export const initialValues: NewLotteryPayload = {
  price: 1,
  title: "",
  numbers: 1,
  awards: [],
  end_date: "",
  draw_date: "",
  start_date: "",
  description: "",
};

export const createLotterySchema = Yup.object().shape({
  title: Yup.string().required("Este campo es obligatorio"),
  end_date: Yup.string().required("Este campo es obligatorio"),
  draw_date: Yup.string().required("Este campo es obligatorio"),
  start_date: Yup.string().required("Este campo es obligatorio"),
  description: Yup.string().required("Este campo es obligatorio"),
  numbers: Yup.number()
    .min(1, "No puede ser inferior a 1")
    .required("Este campo es obligatorio"),
  price: Yup.number()
    .min(1, "No puede ser inferior a 1")
    .required("Este campo es obligatorio"),
});
