import {api} from "api";
import {close, toggleButtonLoaderId} from "../";
import {thunkSuccess} from "@redux/thunk-success";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";
import {GiveCreditPayload, UserModel} from "interfaces";

export const get = createAsyncThunk(
  "users/get",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<{data: UserModel[]; total: number}>(
        "/users/getAll?limit=50000&position=0&search=&role=2"
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const remove = createAsyncThunk(
  "users/remove",
  async (userId: string, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("remove-user"));
    try {
      await api.Delete(`/users/delete/${userId}`);
      dispatch(close());
      thunkSuccess(dispatch, {title: "Usuario eliminado"});
      return userId;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("remove-user"));
    }
  }
);

export const giveCredit = createAsyncThunk(
  "users/give-credit",
  async (payload: GiveCreditPayload, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("give-credit"));
    try {
      await api.Post("/users/insertCredit", payload);
      dispatch(get());
      dispatch(close());
      thunkSuccess(dispatch, {title: "Créditos enviados con éxito"});
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("give-credit"));
    }
  }
);
