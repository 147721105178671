import {Formik} from "formik";
import {Container} from "./styled";
import {useAppDispatch} from "hooks";
import {UserModel} from "interfaces";
import {giveCredit} from "@redux/slices/users/thunks";
import {Input, Column, Button, Typography} from "components";
import {giveCreditSchema, initialValues} from "./form.schema";

interface GiveCreditProps {
  user: UserModel;
}

export const GiveCredit: React.FC<GiveCreditProps> = ({user}) => {
  const dispatch = useAppDispatch();

  return (
    <Container>
      <Column>
        <Typography fontSize={20} fontFamily="Poppins-SemiBold">
          Dar créditos a {user["name"]}
        </Typography>
        <Formik
          enableReinitialize
          validationSchema={giveCreditSchema}
          initialValues={{...initialValues, userId: user["_id"] as string}}
          onSubmit={(payload) => {
            dispatch(giveCredit(payload));
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={5} width="100%">
                <Input
                  type="number"
                  name="tickets"
                  placeholder="1"
                  value={values["tickets"]}
                  label="Cantidad de tickets"
                  touched={touched["tickets"]}
                  onBlur={handleBlur("tickets")}
                  onChange={handleChange("tickets")}
                  helperErrorText={errors["tickets"]}
                />
                <Column width="100%" alignItems="center">
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    loaderId="give-credit"
                    label="Enviar créditos"
                    style={{width: "100%"}}
                    onClick={() => handleSubmit()}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};
