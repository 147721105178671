import {Formik} from "formik";
import {Container} from "./styled";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {initialValues, formSchema} from "./form.schema";
import {addResponse} from "@redux/slices/support/thunks";
import {Button, Column, Input, Textarea} from "components";

interface FormProps {}

export const Form: React.FC<FormProps> = () => {
  const dispatch = useAppDispatch();
  const {ticket} = useAppSelector("support");
  const {ticketId} = useParams() as {ticketId: string};
  const {name, lastname, responses} = ticket;

  return (
    <Container>
      <Formik
        validationSchema={formSchema}
        initialValues={{
          ...initialValues,
          title: `Respuesta a ${name} ${lastname} #${responses["length"] + 1}`,
        }}
        onSubmit={(payload) => {
          dispatch(addResponse({...payload, ticketId}));
        }}
      >
        {({
          dirty,
          errors,
          isValid,
          values,
          touched,
          handleBlur,
          handleSubmit,
          handleChange,
        }) => {
          const isDisabled = !(dirty && isValid);
          return (
            <Column alignItems="center" justifyContent="center" gap={15}>
              <Column gap={0} width="100%">
                <Input
                  name="title"
                  label="Título"
                  value={values["title"]}
                  touched={touched["title"]}
                  placeholder="Título"
                  onBlur={handleBlur("title")}
                  onChange={handleChange("title")}
                  helperErrorText={errors["title"]}
                />
                <Textarea
                  autoFocus
                  name="description"
                  title="Descripción"
                  placeholder="Respuesta..."
                  value={values["description"]}
                  touched={touched["description"]}
                  onBlur={handleBlur("description")}
                  onChange={handleChange("description")}
                  helperErrorText={errors["description"]}
                />
              </Column>
              <Button
                type="submit"
                disabled={isDisabled}
                style={{width: "100%"}}
                label="Agregar respuesta"
                onClick={() => handleSubmit()}
                loaderId="add-support-ticket-response"
              />
            </Column>
          );
        }}
      </Formik>
    </Container>
  );
};
