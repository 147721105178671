import {api} from "api";
import {setFilter} from ".";
import {close} from "../modal";
import {PaymentModel} from "interfaces";
import {GetPaymentsFilter} from "./types";
import {toggleButtonLoaderId} from "../layout";
import {thunkSuccess} from "@redux/thunk-success";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";

export const get = createAsyncThunk(
  "payments/get",
  async (filter: GetPaymentsFilter, {dispatch, rejectWithValue}) => {
    const {lim, pos, ser, method, status} = filter;
    dispatch(setFilter(filter));
    let query = `/payments/getAll?limit=${lim}&position=${pos}&search=${ser}`;
    if (method) query += `&method=${method}`;
    if (status) query += `&status=${status}`;
    try {
      return await api.Get<PaymentModel[]>(query);
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const changePaymentStatus = createAsyncThunk(
  "payments/change-payment-status",
  async (payload: PaymentModel, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("change-payment-status"));
    try {
      const {data} = await api.Put<PaymentModel>(
        `/payments/update/${payload["_id"]}`,
        payload
      );
      dispatch(close());
      thunkSuccess(dispatch, {title: "Estado del pago actualizado con éxito"});
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("change-payment-status"));
    }
  }
);
