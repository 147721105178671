import {Characteristic} from "interfaces";

export function onChangeCharacteristic(
  arr: Characteristic[],
  characteristicIndex: number,
  newValueInput: string,
  characteristicKey: keyof Characteristic
) {
  let temp = [...arr];
  let updated = {...temp[characteristicIndex]};
  updated[characteristicKey] = newValueInput;
  temp[characteristicIndex] = updated;
  return temp;
}
