import {colors} from "styles";
import {FC, Fragment} from "react";
import {authRoutes} from "routing";
import {Pathname} from "interfaces";
import {useAppSelector} from "hooks";
import {Container, Href} from "./styled";
import {Logo} from "components/@system/Logo";
import {useLocation, useNavigate} from "react-router-dom";
import {Column, Line, Typography} from "components/@system";
import {
  Map,
  Home,
  Award,
  Users,
  BarChart,
  Settings,
  ThumbsUp,
  IconProps,
  RotateCcw,
  DollarSign,
  HelpCircle,
} from "react-feather";

interface SidebarProps {}

const icons: Record<Exclude<Pathname, "*">, FC<IconProps>> = {
  "/": Home,
  "/users": Users,
  "/locations": Map,
  "/config": Settings,
  "/support": HelpCircle,
  "/statistics": BarChart,
  "/testimonials": ThumbsUp,
  "/history/ranking": Award,
  "/history/lottery": RotateCcw,
  "/history/payments": DollarSign,
};

const NOT_SIDEBAR_PAGES: Pathname[] = [
  "*",
  "/support/:ticketId",
  "/history/lottery/:lotteryId",
];

export const Sidebar: React.FC<SidebarProps> = () => {
  const push = useNavigate();
  const {pathname} = useLocation();
  const {isSuperAdmin} = useAppSelector("auth");

  return (
    <Container>
      <Logo />
      <Line backgroundColor="rgba(255, 255, 255, .1)" />
      <Column gap={2}>
        {authRoutes
          .filter(({path}) => !NOT_SIDEBAR_PAGES.includes(path))
          .map(({label, path, superAdminOnly}, key) => {
            const activePath = pathname === path;
            const Icon = icons[path as Exclude<Pathname, "*">];
            const color = activePath ? colors["white"] : "#6A6B6C";
            if (superAdminOnly && !isSuperAdmin) return <Fragment />;

            return (
              <Href
                key={key}
                onClick={() => push(path)}
                style={{
                  backgroundColor: activePath
                    ? "rgba(255, 255, 255, .1)"
                    : undefined,
                }}
              >
                <Icon color={color} size={25} />
                <Typography color={color}>{label}</Typography>
              </Href>
            );
          })}
      </Column>
    </Container>
  );
};
