import {State} from "./types";
import {setRequestStatus} from "@redux/request-status";
import {lotteryInitialState} from "@redux/initial-states";

export const initialState: State = {
  lotteries: [],
  lottery: lotteryInitialState,
  request: {
    lottery: setRequestStatus("initial"),
    lotteries: setRequestStatus("initial"),
  },
};
