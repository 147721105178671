import {storage} from "utils";
import {login} from "./thunks";
import {UserModel} from "interfaces";
import {initialState} from "./initial-state";
import {userInitialState} from "@redux/initial-states";
import {createSlice, isAnyOf, PayloadAction} from "@reduxjs/toolkit";

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setLoggedUser: function (state, action: PayloadAction<UserModel>) {
      state["isLogged"] = true;
      state["user"] = action["payload"];
      state["isSuperAdmin"] = action["payload"]["role"] === 1;
    },
    logOut: function (state) {
      window.location.href = "/";
      state["isLogged"] = false;
      state["user"] = userInitialState;

      storage["delete"]("[user]");
      storage["delete"]("[auth-token]");
    },
  },
  extraReducers: function (builder) {
    builder.addMatcher(isAnyOf(login.fulfilled), function (state, action) {
      const {data} = action["payload"];
      const {token, ...user} = data;

      state["user"] = user;
      state["isLogged"] = true;
      state["isSuperAdmin"] = user["role"] === 1;

      storage["set"]("[user]", user);
      storage["set"]("[auth-token]", token);
    });
  },
});

export const {setLoggedUser, logOut} = authSlice["actions"];
export const {reducer: auth} = authSlice;
