import moment from "moment";

export const dateDiff = (startDate: Date): string => {
  const now = moment(new Date());
  var duration = moment.duration(now.diff(startDate));

  const days = duration.days();
  duration.subtract(days, "days");

  const hours = duration.hours();
  duration.subtract(hours, "hours");

  const minutes = duration.minutes();
  duration.subtract(minutes, "minutes");

  const seconds = duration.seconds();

  let displayDiff = `${days > 0 ? `${days} días ` : ""}${
    hours > 0 ? `${hours} horas ` : ""
  }${
    minutes > 0 && hours > 0
      ? `y ${minutes} minutos`
      : minutes > 0 && hours <= 0
      ? `${minutes} minutos`
      : ""
  }`;

  if (days <= 0 && hours <= 0 && minutes <= 0) {
    displayDiff = `${seconds} segundos`;
  }

  return displayDiff;
};
