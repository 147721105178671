import {useAppSelector} from "hooks";
import {LotteryModel} from "interfaces";
import {useTheme} from "styled-components";
import {Column, Row, Typography} from "components";
import {Container, Png, Statistic} from "./styled";
import {dateDiff, dateLeft, formatDate} from "utils";
import {CircularProgressbar} from "react-circular-progressbar";

interface StatisticsProps {
  lottery: LotteryModel;
}

export const Statistics: React.FC<StatisticsProps> = ({lottery}) => {
  const {primary, white} = useTheme();
  const {monitors} = useAppSelector("config")["rate"];
  const {
    price,
    status,
    numbers,
    end_date,
    totalUSD,
    totalVES,
    start_date,
    totalCredits,
    totalTicketsSold,
  } = lottery;
  const inProgress = status === 1;

  return (
    <Column width="100%">
      <Typography fontSize={18} fontFamily="Poppins-SemiBold">
        Resumen
      </Typography>
      <Container>
        <Statistic>
          <Row>
            <Png src="/images/tickets.png" />
            <Typography color={white} fontSize={16.5}>
              Tickets vendidos
            </Typography>
          </Row>
          <Column>
            <CircularProgressbar
              circleRatio={1}
              maxValue={numbers}
              value={totalTicketsSold}
              text={`${totalTicketsSold}/${numbers}`}
              styles={{
                text: {fill: white},
                path: {stroke: primary},
                root: {width: 85, height: 85},
              }}
            />
          </Column>
        </Statistic>
        <Statistic>
          <Row>
            <Png src="/images/dollars.png" />
            <Typography color={white} fontSize={16.5}>
              Total de ganancias (USD)
            </Typography>
          </Row>
          <Column gap={0}>
            <Typography color={white} fontFamily="Poppins-Medium" fontSize={32}>
              ${totalUSD.toLocaleString("US")}
            </Typography>
            <Typography color={white} fontSize={14}>
              1 ticket = ${price}
            </Typography>
          </Column>
        </Statistic>
        <Statistic>
          <Row>
            <Png src="/images/dollars.png" />
            <Typography color={white} fontSize={16.5}>
              Total de ganancias (Bs)
            </Typography>
          </Row>
          <Column gap={0}>
            <Typography color={white} fontFamily="Poppins-Medium" fontSize={32}>
              Bs. {totalVES.toLocaleString()}
            </Typography>
            <Typography color={white} fontSize={14}>
              1 ticket = Bs{" "}
              {(price * monitors["usd"]["price"]).toLocaleString("US")}
            </Typography>
          </Column>
        </Statistic>
        <Statistic>
          <Row>
            <Png src="/images/tickets.png" />
            <Typography color={white} fontSize={16.5}>
              Tickets Regalados (cupones)
            </Typography>
          </Row>
          <Column gap={0}>
            <Typography color={white} fontFamily="Poppins-Medium" fontSize={32}>
              {totalCredits}
            </Typography>
          </Column>
        </Statistic>
        <Statistic>
          <Row>
            <Png src="/images/time.png" />
            <Typography color={white} fontSize={16.5}>
              Tiempo transcurrido
            </Typography>
          </Row>
          <Column gap={0}>
            <Typography color={white} fontSize={20} fontFamily="Poppins-Medium">
              {inProgress
                ? dateDiff(formatDate(start_date) as unknown as Date)
                : "Completado"}
            </Typography>
          </Column>
        </Statistic>
        <Statistic>
          <Row>
            <Png src="/images/time.png" />
            <Typography color={white} fontSize={16.5}>
              Tiempo restante
            </Typography>
          </Row>
          <Column gap={0}>
            <Typography color={white} fontSize={20} fontFamily="Poppins-Medium">
              {inProgress ? dateLeft(end_date as Date) : "Ninguno"}
            </Typography>
          </Column>
        </Statistic>
      </Container>
    </Column>
  );
};
