import {api} from "api";
import {postFormData} from "utils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {thunkBadRequest} from "@redux/thunk-error";
import {toggleButtonLoaderId, close, showAlert} from "../";
import {
  CityModel,
  StateModel,
  APIResponse,
  CountryModel,
  CreateCityPayload,
  CreateStatePayload,
  CreateCountryPayload,
} from "interfaces";

export const getCountries = createAsyncThunk(
  "location/get-countries",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<CountryModel[]>(
        "/geographical-division/country/getAll?limit=5000&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getStates = createAsyncThunk(
  "location/get-states",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<StateModel[]>(
        "/geographical-division/state/getAll?limit=5000&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const getCities = createAsyncThunk(
  "location/get-cities",
  async (_, {dispatch, rejectWithValue}) => {
    try {
      return await api.Get<CityModel[]>(
        "/geographical-division/city/getAll?limit=5000&position=0&search="
      );
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    }
  }
);

export const createCountry = createAsyncThunk(
  "location/create-country",
  async (payload: CreateCountryPayload, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("create-country"));
    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }
    try {
      const {data} = (await postFormData<CountryModel>(
        "/geographical-division/country/create",
        formData,
        dispatch
      )) as APIResponse<CountryModel>;

      dispatch(close());
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("create-country"));
    }
  }
);

export const createState = createAsyncThunk(
  "location/create-state",
  async (payload: CreateStatePayload, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("create-state"));
    try {
      const {data} = await api.Post<StateModel>(
        "/geographical-division/state/create",
        payload
      );
      dispatch(close());
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("create-state"));
    }
  }
);

export const createCity = createAsyncThunk(
  "location/create-city",
  async (payload: CreateCityPayload, {dispatch, rejectWithValue}) => {
    dispatch(toggleButtonLoaderId("create-city"));
    try {
      const {data} = await api.Post<CityModel>(
        "/geographical-division/city/create",
        payload
      );
      dispatch(close());
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("create-city"));
    }
  }
);

export const updateCountry = createAsyncThunk(
  "location/update-country",
  async (payload: CountryModel, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("update-country"));

    const formData = new FormData();
    for (const [key, value] of Object.entries(payload)) {
      formData.append(key, value);
    }

    try {
      const {data} = (await postFormData<CountryModel>(
        `/geographical-division/country/update/${payload["_id"]}`,
        formData,
        dispatch,
        "PUT"
      )) as APIResponse<CountryModel>;

      dispatch(
        showAlert({type: "success", title: "País actualizado con éxito"})
      );
      dispatch(close());
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("update-country"));
    }
  }
);

export const updateState = createAsyncThunk(
  "location/update-state",
  async (payload: StateModel, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("update-state"));

    try {
      const {data} = await api.Put<StateModel>(
        `/geographical-division/state/update/${payload["_id"]}`,
        payload
      );

      dispatch(
        showAlert({type: "success", title: "Estado actualizado con éxito"})
      );
      dispatch(close());
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("update-state"));
    }
  }
);

export const updateCity = createAsyncThunk(
  "location/update-city",
  async (payload: CityModel, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("update-city"));

    try {
      const {data} = await api.Put<CityModel>(
        `/geographical-division/city/update/${payload["_id"]}`,
        payload
      );

      dispatch(
        showAlert({type: "success", title: "Ciudad actualizada con éxito"})
      );
      dispatch(close());
      return data;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("update-city"));
    }
  }
);

export const removeCountry = createAsyncThunk(
  "location/remove-country",
  async (countryId: string, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("remove-country"));
    try {
      await api.Delete<CountryModel>(
        `/geographical-division/country/delete/${countryId}`
      );
      dispatch(showAlert({type: "success", title: "País eliminado con éxito"}));
      dispatch(close());
      return countryId;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("remove-country"));
    }
  }
);

export const removeState = createAsyncThunk(
  "location/remove-state",
  async (stateId: string, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("remove-state"));
    try {
      await api.Delete<StateModel>(
        `/geographical-division/state/delete/${stateId}`
      );
      dispatch(
        showAlert({type: "success", title: "Estado eliminado con éxito"})
      );
      dispatch(close());
      return stateId;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("remove-state"));
    }
  }
);

export const removeCity = createAsyncThunk(
  "location/remove-city",
  async (cityId: string, {rejectWithValue, dispatch}) => {
    dispatch(toggleButtonLoaderId("remove-city"));
    try {
      await api.Delete<CityModel>(
        `/geographical-division/city/delete/${cityId}`
      );
      dispatch(
        showAlert({type: "success", title: "Ciudad eliminada con éxito"})
      );
      dispatch(close());
      return cityId;
    } catch (error) {
      thunkBadRequest(dispatch, error);
      return rejectWithValue(error);
    } finally {
      dispatch(toggleButtonLoaderId("remove-city"));
    }
  }
);
