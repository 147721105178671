import moment from "moment";
import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {Eye, HelpCircle} from "react-feather";
import {get} from "@redux/slices/support/thunks";
import {useAppDispatch, useAppSelector} from "hooks";
import {
  Chip,
  Table,
  Header,
  Column,
  Skeleton,
  Typography,
  IconButton,
  ComponentLoader,
  SuperAdminRoute,
} from "components";

interface SupportProps {}

export const Support: React.FC<SupportProps> = () => {
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const {tickets, request} = useAppSelector("support");
  const {white, heart, container, secondary, blackWhite} = useTheme();

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SuperAdminRoute>
      <Column gap={0}>
        <Header children="Soporte" icon={HelpCircle} />
        <ComponentLoader
          requestStatus={request["tickets"]}
          skeleton={
            <Column gap={1} width="100%">
              <Skeleton count={8} width="100%" height={40} />
            </Column>
          }
        >
          <Container>
            <Table
              data={tickets}
              columns="200px 150px 250px 200px 120px"
              header={
                <Column gap={0} marginBottom={15}>
                  <Typography>
                    Recuerda responder los tickets que aún no han recibido
                    feedback de parte de un administrador
                  </Typography>
                </Column>
              }
              head={[
                {label: "Usuario"},
                {label: "Creación"},
                {label: "Título"},
                {label: "Estatus"},
                {label: "Acciones"},
              ]}
              emptyUI={{
                message: "No hay registros de tickets",
                icon: <HelpCircle size={40} color={blackWhite} />,
              }}
            >
              {({_id, name, title, lastname, responses, createdAt}) => {
                const waiting = !responses["length"];
                return (
                  <Table.Tr backgroundColor={container}>
                    <Table.Td gap={6} overflow="hidden">
                      <Typography fontFamily="Poppins-Medium">
                        {name} {lastname}
                      </Typography>
                    </Table.Td>
                    <Table.Td>
                      <Typography>{moment(createdAt).fromNow()}</Typography>
                    </Table.Td>
                    <Table.Td>
                      <Typography className="line-clamp-1">{title}</Typography>
                    </Table.Td>
                    <Table.Td>
                      <Chip backgroundColor={waiting ? heart : secondary}>
                        <Typography color={white}>
                          {waiting ? "Sín respuesta" : "Respondido"}
                        </Typography>
                      </Chip>
                    </Table.Td>
                    <Table.Td gap={6}>
                      <IconButton
                        toolTip="Ver Ticket"
                        icon={<Eye color={white} />}
                        onClick={() => push(`/support/${_id}`)}
                      />
                    </Table.Td>
                  </Table.Tr>
                );
              }}
            </Table>
          </Container>
        </ComponentLoader>
      </Column>
    </SuperAdminRoute>
  );
};
