import {Formik} from "formik";
import {Container} from "./styled";
import {CityModel} from "interfaces";
import {buildSelectOptions} from "utils";
import {useAppDispatch, useAppSelector} from "hooks";
import {citySchema, initialValues} from "./form.schema";
import {createCity, updateCity} from "@redux/slices/location/thunks";
import {Input, Column, Button, Typography, Select} from "components";

interface CityFormProps {
  defaultValues?: CityModel;
}

export const CityForm: React.FC<CityFormProps> = ({defaultValues}) => {
  const dispatch = useAppDispatch();
  const {states} = useAppSelector("location");
  const editable = typeof defaultValues !== "undefined";
  const data = editable ? defaultValues : initialValues;

  return (
    <Container>
      <Column>
        <Column width="100%" gap={0}>
          <Typography fontSize={20} fontFamily="Poppins-SemiBold">
            Nueva ciudad
          </Typography>
        </Column>
        <Formik
          initialValues={data}
          validationSchema={citySchema}
          onSubmit={(payload) => {
            if (editable) {
              dispatch(updateCity({...defaultValues, ...payload}));
            } else {
              dispatch(createCity(payload));
            }
          }}
        >
          {({
            dirty,
            errors,
            values,
            isValid,
            touched,
            handleBlur,
            handleSubmit,
            handleChange,
          }) => {
            const isDisabled = !(dirty && isValid);
            return (
              <Column gap={0} width="100%">
                <Select
                  name="stateId"
                  value={values["stateId"]}
                  touched={touched["stateId"]}
                  onBlur={handleBlur("stateId")}
                  onChange={handleChange("stateId")}
                  helperErrorText={errors["stateId"]}
                  options={buildSelectOptions(states, "Selecciona un estado", {
                    keyValue: "_id",
                    keyLabel: "name",
                  })}
                />
                <Input
                  name="name"
                  label="Nombre"
                  value={values["name"]}
                  touched={touched["name"]}
                  placeholder="Lara"
                  onBlur={handleBlur("name")}
                  onChange={handleChange("name")}
                  helperErrorText={errors["name"]}
                />
                <Column width="100%" alignItems="center" marginTop={5}>
                  <Button
                    type="submit"
                    disabled={isDisabled}
                    style={{width: "100%"}}
                    onClick={() => handleSubmit()}
                    label={editable ? "Actualizar" : "Crear"}
                    loaderId={editable ? "update-city" : "create-city"}
                  />
                </Column>
              </Column>
            );
          }}
        </Formik>
      </Column>
    </Container>
  );
};
