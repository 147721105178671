import styled from "styled-components";
import {pickButtonColor} from "styles";

export const ButtonStyled = styled.button<{
  $disabled: boolean;
  $darkMode: boolean;
}>`
  height: 45px;
  border: unset;
  outline: unset;
  cursor: pointer;
  padding: 0 1rem;
  border-radius: 100px;
  background-color: unset;
  transition: all ease 0.3s;

  &:active {
    transform: scale(0.97);
  }
  &:hover {
    background-color: ${({theme, $disabled}) =>
      $disabled ? "" : theme["buttonHover"]};
  }
`;
