import * as Yup from "yup";
import {NewTestimonialPayload} from "interfaces";

export const initialValues: NewTestimonialPayload = {
  files: [],
  message: "",
  awardId: "",
};

export const createLotterySchema = Yup.object().shape({
  message: Yup.string().required("Este campo es obligatorio"),
  awardId: Yup.string().required("Este campo es obligatorio"),
});
