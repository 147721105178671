import {get} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";

export const referralSlice = createSlice({
  name: "referral",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["referrals"] = setRequestStatus("initial");
    });

    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["referrals"] = data;
      state["request"]["referrals"] = setRequestStatus("success");
    });

    builder.addCase(get.rejected, function (state) {
      state["request"]["referrals"] = setRequestStatus("error");
    });
  },
});

// export const {} = referralSlice["actions"];
export const {reducer: referral} = referralSlice;
