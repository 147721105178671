import {motion} from "framer-motion";
import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const Field = styled.div`
  width: 100%;
  height: 50px;
  display: grid;
  cursor: pointer;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 6px;
  padding: 0 0.8rem;
  align-items: center;
  transition: all ease 0.3s;
  justify-content: space-between;
  grid-template-columns: auto 25px;
  color: ${({theme}) => theme["white"]};
  background-color: ${({theme}) => theme["container"]};
`;

export const DropdownStyled = styled(motion.div)`
  left: 0%;
  top: 54px;
  z-index: 10;
  width: 100%;
  border-top: 0;
  padding: 0.3rem;
  max-height: 400px;
  overflow-y: auto;
  position: absolute;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 2px 4px 3px 1px rgba(0, 0, 0, 0.03),
    -2px 4px 3px 1px rgba(0, 0, 0, 0.03);
  background-color: ${({theme}) => theme["container"]};

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${({theme}) => theme["line"]};
  }
`;

export const DropdownItem = styled.div<{$highlighted: boolean}>`
  color: #fff;
  padding: 1rem;
  cursor: pointer;
  transition: all ease 0.3s;
  background-color: ${({$highlighted, theme}) =>
    $highlighted ? theme["primary"] : "transparent"};
  &:hover {
    background-color: ${({$highlighted, theme}) =>
      $highlighted ? theme["primary"] : theme["primaryTransparent"]};
  }
  border-bottom: 0.5px solid ${({theme}) => theme["line"]};

  &:first-child {
    border-radius: 5px 5px 0 0;
  }
  &:last-child {
    border-bottom: unset;
    border-radius: 0 0 5px 5px;
  }
`;
