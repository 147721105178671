import styled from "styled-components";

export const Container = styled.div`
  height: 26px;
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
`;
