import styled from "styled-components";

export const Container = styled.aside`
  width: 100%;
  height: 100%;
  padding: 1rem;
  padding-top: 1.5rem;
  background-color: #030303;
`;

export const Href = styled.div`
  border: unset;
  width: 100%;
  height: 46px;
  display: grid;
  grid-gap: 10px;
  cursor: pointer;
  padding: 0 1rem;
  border-radius: 5px;
  align-items: center;
  background-color: unset;
  transition: 0.3s all ease;
  grid-template-columns: 30px 1fr;
  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
`;
