import {Container} from "./styled";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {CustomAccordionItemProps} from "./lib";
import {AccordionItem} from "./lib/CustomAccordionItem/styled";
import {Accordion as SzhsinAccordion} from "@szhsin/react-accordion";

export type AccordionItemProps = Omit<
  CustomAccordionItemProps,
  "itemIndex" | "activeIndex" | "onHeaderClick" | "content"
> & {
  content: JSX.Element;
  initialEntered?: boolean;
};

interface AccordionProps {
  items: AccordionItemProps[];
  initialEnteredItemIndex?: number;
  onExpanded?: (itemExpanded: CustomAccordionItemProps) => void;
}

export const Accordion: React.FC<AccordionProps> = ({
  items,
  onExpanded,
  initialEnteredItemIndex,
}) => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState<number | undefined>(
    initialEnteredItemIndex
  );

  useEffect(() => {
    setActiveIndex(items.findIndex((item) => item["initialEntered"]));
  }, [items]);

  return (
    <Container>
      <SzhsinAccordion transition transitionTimeout={250}>
        {items.map(({header, content, tag, initialEntered = false}, key) => (
          <AccordionItem
            id={tag}
            key={key}
            tag={tag}
            itemIndex={key}
            header={header}
            activeIndex={activeIndex}
            initialEntered={
              typeof initialEnteredItemIndex !== "undefined"
                ? initialEnteredItemIndex === key
                : initialEntered
            }
            onHeaderClick={(item) => {
              setActiveIndex(item["itemIndex"]);
              if (tag) navigate(`#${tag}`);
              if (typeof onExpanded !== "undefined") onExpanded(item);
            }}
          >
            {content}
          </AccordionItem>
        ))}
      </SzhsinAccordion>
    </Container>
  );
};
