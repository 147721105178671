import {useEffect} from "react";
import {useTheme} from "styled-components";
import {Edit, Map, Plus, Trash} from "react-feather";
import {CityForm, CountryForm, StateForm} from "./lib";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {
  getStates,
  getCities,
  removeCity,
  removeState,
  getCountries,
  removeCountry,
} from "@redux/slices/location/thunks";
import {
  Row,
  Line,
  Table,
  Header,
  Column,
  Confirm,
  Skeleton,
  IconButton,
  Typography,
  ComponentLoader,
  SuperAdminRoute,
} from "components";

interface LocationsProps {}

export const Locations: React.FC<LocationsProps> = () => {
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {container, primary, heart, white} = useTheme();
  const {countries, states, cities, request} = useAppSelector("location");

  useEffect(() => {
    dispatch(getCities());
    dispatch(getStates());
    dispatch(getCountries());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SuperAdminRoute>
      <Column>
        <Header icon={Map} children="Ubicaciones" />
        <ComponentLoader
          requestStatus={request["countries"]}
          skeleton={
            <Column width="100%">
              <Skeleton width={200} height="50px" />
              <Column gap={1} width="100%">
                <Skeleton count={5} width="100%" height="50px" />
              </Column>
            </Column>
          }
        >
          <Table
            data={countries}
            style={{width: "100%"}}
            columns="50px 120px 90px 90px 120px"
            emptyUI={{
              icon: <Map size={40} />,
              message: "No hay países creados",
            }}
            header={
              <Row gap={10} marginBottom={10}>
                <Typography fontSize={18} fontFamily="Poppins-Medium">
                  Países
                </Typography>
                <IconButton
                  size={30}
                  toolTip="Agregar país"
                  toolTipPosition="down"
                  backgroundColor={primary}
                  icon={<Plus size={20} color={white} />}
                  onClick={() => openModal(<CountryForm />)}
                />
              </Row>
            }
            head={[
              {label: ""},
              {label: "Nombre"},
              {label: "Código"},
              {label: "Prefijo"},
              {label: "Acciones"},
            ]}
          >
            {(country, key) => {
              const {name, code, prefix, _id} = country;
              return (
                <Table.Tr cursor="pointer" backgroundColor={container}>
                  <Table.Td>
                    <Typography fontSize={15}>{key + 1}</Typography>
                  </Table.Td>
                  <Table.Td gap={6} overflow="hidden">
                    <Typography fontSize={15} fontFamily="Poppins-Medium">
                      {name}
                    </Typography>
                  </Table.Td>
                  <Table.Td>
                    <Typography fontSize={16}>{code}</Typography>
                  </Table.Td>
                  <Table.Td>
                    <Typography fontSize={16}>{prefix}</Typography>
                  </Table.Td>
                  <Table.Td gap={5}>
                    <IconButton
                      size={35}
                      icon={<Edit color={white} size={20} />}
                      onClick={() =>
                        openModal(<CountryForm defaultValues={country} />)
                      }
                    />
                    <IconButton
                      size={35}
                      toolTipStyle={{backgroundColor: heart}}
                      icon={<Trash size={20} color={heart} />}
                      onClick={() =>
                        openModal(
                          <Confirm
                            title={`Eliminar país: ${name}`}
                            message="Presiona Eliminar para continuar. Una vez eliminado no se podrá recuperar esta información"
                            actions={[
                              {
                                label: "Eliminar",
                                loaderId: "remove-country",
                                style: {backgroundColor: heart},
                                onClick: () =>
                                  dispatch(removeCountry(_id as string)),
                              },
                              {
                                label: "Cancelar",
                                onClick: () => closeModal(),
                                style: {backgroundColor: "#bcbcbcec"},
                              },
                            ]}
                          />
                        )
                      }
                    />
                  </Table.Td>
                </Table.Tr>
              );
            }}
          </Table>
        </ComponentLoader>
        <Line />
        <ComponentLoader
          requestStatus={request["states"]}
          skeleton={
            <Column width="100%">
              <Skeleton width={200} height="50px" />
              <Column gap={1} width="100%">
                <Skeleton count={5} width="100%" height="50px" />
              </Column>
            </Column>
          }
        >
          <Table
            data={states}
            columns="50px 200px 100px 120px"
            style={{width: "100%"}}
            emptyUI={{
              icon: <Map size={40} />,
              message: "No hay estados creados",
            }}
            header={
              <Row gap={10} marginBottom={10}>
                <Typography fontSize={18} fontFamily="Poppins-Medium">
                  Estados
                </Typography>
                <IconButton
                  size={30}
                  toolTipPosition="down"
                  toolTip="Agregar estado"
                  backgroundColor={primary}
                  icon={<Plus size={20} color={white} />}
                  onClick={() => openModal(<StateForm />)}
                />
              </Row>
            }
            head={[
              {label: ""},
              {label: "Nombre"},
              {label: "Código"},
              {label: "Acciones"},
            ]}
          >
            {(state, key) => {
              const {name, code, _id} = state;
              return (
                <Table.Tr cursor="pointer" backgroundColor={container}>
                  <Table.Td>
                    <Typography fontSize={15}>{key + 1}</Typography>
                  </Table.Td>
                  <Table.Td gap={6} overflow="hidden">
                    <Typography fontSize={15} fontFamily="Poppins-Medium">
                      {name}
                    </Typography>
                  </Table.Td>
                  <Table.Td>
                    <Typography fontSize={16}>{code}</Typography>
                  </Table.Td>
                  <Table.Td gap={5}>
                    <IconButton
                      size={35}
                      icon={<Edit color={white} size={20} />}
                      onClick={() =>
                        openModal(<StateForm defaultValues={state} />)
                      }
                    />
                    <IconButton
                      size={35}
                      icon={<Trash size={20} color={heart} />}
                      toolTipStyle={{backgroundColor: heart}}
                      onClick={() =>
                        openModal(
                          <Confirm
                            title={`Eliminar Estado: ${name}`}
                            message="Presiona Eliminar para continuar. Una vez eliminado no se podrá recuperar esta información"
                            actions={[
                              {
                                label: "Eliminar",
                                loaderId: "remove-state",
                                style: {backgroundColor: heart},
                                onClick: () =>
                                  dispatch(removeState(_id as string)),
                              },
                              {
                                label: "Cancelar",
                                onClick: () => closeModal(),
                                style: {backgroundColor: "#bcbcbcec"},
                              },
                            ]}
                          />
                        )
                      }
                    />
                  </Table.Td>
                </Table.Tr>
              );
            }}
          </Table>
        </ComponentLoader>
        <Line />
        <ComponentLoader
          requestStatus={request["cities"]}
          skeleton={
            <Column width="100%">
              <Skeleton width={200} height="50px" />
              <Column gap={1} width="100%">
                <Skeleton count={5} width="100%" height="50px" />
              </Column>
            </Column>
          }
        >
          <Table
            data={cities}
            style={{width: "100%"}}
            columns="50px 140px 110px"
            head={[{label: ""}, {label: "Nombre"}, {label: "Acciones"}]}
            header={
              <Row gap={10} marginBottom={10}>
                <Typography fontSize={18} fontFamily="Poppins-Medium">
                  Ciudades
                </Typography>
                <IconButton
                  size={30}
                  toolTipPosition="down"
                  toolTip="Agregar ciudad"
                  backgroundColor={primary}
                  icon={<Plus size={20} color={white} />}
                  onClick={() => openModal(<CityForm />)}
                />
              </Row>
            }
            emptyUI={{
              icon: <Map size={40} />,
              message: "No hay ciudades creadas",
            }}
          >
            {(city, key) => {
              const {name, _id} = city;
              return (
                <Table.Tr cursor="pointer" backgroundColor={container}>
                  <Table.Td>
                    <Typography fontSize={15}>{key + 1}</Typography>
                  </Table.Td>
                  <Table.Td gap={6} overflow="hidden">
                    <Typography fontSize={15} fontFamily="Poppins-Medium">
                      {name}
                    </Typography>
                  </Table.Td>
                  <Table.Td gap={5}>
                    <IconButton
                      size={35}
                      icon={<Edit color={white} size={20} />}
                      onClick={() =>
                        openModal(<CityForm defaultValues={city} />)
                      }
                    />
                    <IconButton
                      size={35}
                      icon={<Trash size={20} color={heart} />}
                      toolTipStyle={{backgroundColor: heart}}
                      onClick={() =>
                        openModal(
                          <Confirm
                            title={`Eliminar Ciudad: ${name}`}
                            message="Presiona Eliminar para continuar. Una vez eliminado no se podrá recuperar esta información"
                            actions={[
                              {
                                label: "Eliminar",
                                loaderId: "remove-city",
                                style: {backgroundColor: heart},
                                onClick: () =>
                                  dispatch(removeCity(_id as string)),
                              },
                              {
                                label: "Cancelar",
                                onClick: () => closeModal(),
                                style: {backgroundColor: "#bcbcbcec"},
                              },
                            ]}
                          />
                        )
                      }
                    />
                  </Table.Td>
                </Table.Tr>
              );
            }}
          </Table>
        </ComponentLoader>
      </Column>
    </SuperAdminRoute>
  );
};
