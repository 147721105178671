import {LotteryModel} from "interfaces";

export const lotteryInitialState: LotteryModel = {
  _id: "",
  likes: [],
  price: 0,
  users: [],
  title: "",
  number: 0,
  status: 1,
  media: [],
  numbers: 0,
  awards: [],
  expenses: 0,
  totalUSD: 0,
  totalVES: 0,
  totalCredits: 0,
  description: "",
  numbersArray: [],
  totalTicketsSold: 0,
  end_date: new Date(),
  draw_date: new Date(),
  start_date: new Date(),
};
