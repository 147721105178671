import {Column, Row, Skeleton as UISkeleton} from "components";

export const Skeleton = () => (
  <Column gap={15} maxWidth="850px" margin="0 auto">
    <Row marginBottom={10}>
      <UISkeleton width={45} height={45} borderRadius={100} />
      <UISkeleton width={300} height={45} />
    </Row>
    <Column width="100%">
      <UISkeleton width="100%" height={35} />
      <UISkeleton width="100%" height={120} />
      <UISkeleton width="100%" height={50} />
    </Column>
  </Column>
);
