import {State} from "./types";
import {setRequestStatus} from "@redux/request-status";

export const initialState: State = {
  payments: [],
  filter: {
    pos: 0,
    lim: 1000,
    ser: "",
    method: "",
    status: "",
  },
  request: {
    payments: setRequestStatus("initial"),
  },
};
