import {State} from "./types";
import {setRequestStatus} from "@redux/request-status";
import {supportTicketInitialState} from "@redux/initial-states";

export const initialState: State = {
  tickets: [],
  ticket: supportTicketInitialState,
  request: {
    ticket: setRequestStatus("initial"),
    tickets: setRequestStatus("initial"),
  },
};
