import {initialState} from "./initial-state";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

export const multimediaSlice = createSlice({
  name: "multimedia",
  initialState,
  reducers: {
    openMultimedia: function (state, action: PayloadAction<string[]>) {
      state["assets"] = action["payload"];
      state["shown"] = true;
    },
    closeMultimedia: function (state) {
      state["shown"] = false;
      state["assets"] = [];
    },
  },
});

export const {reducer: multimedia} = multimediaSlice;
export const {openMultimedia, closeMultimedia} = multimediaSlice["actions"];
