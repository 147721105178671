import {useModal} from "hooks";
import {useTheme} from "styled-components";
import {Fragment} from "react/jsx-runtime";
import {Container, Ticket, User} from "./styled";
import {LotteryModel, UserModel} from "interfaces";
import {Avatar, Column, Typography, UserPreview} from "components";

interface TicketsProps {
  lottery: LotteryModel;
}

export const Tickets: React.FC<TicketsProps> = ({lottery}) => {
  const {numbersArray} = lottery;
  const {openModal} = useModal();
  const {white, secondary, container} = useTheme();

  return (
    <Column width="100%">
      <Container>
        {numbersArray.map(({number, user}, key) => (
          <Ticket
            key={key}
            style={{
              backgroundColor: user ? secondary : container,
              cursor: user ? "pointer" : "default",
            }}
            onClick={() => {
              if (user)
                openModal(
                  <UserPreview user={user as UserModel} ticket={number} />
                );
            }}
          >
            <Typography fontFamily="Poppins-Medium">{number}</Typography>
            {user && (
              <Fragment>
                <Avatar
                  size={25}
                  right={-3}
                  bottom={-3}
                  position="absolute"
                  src={user["picture"]}
                  border={`2.5px solid #030303`}
                />
                <User className="user-hovered">
                  <Typography
                    fontSize={13}
                    color={white}
                    className="line-clamp-1"
                  >
                    {user["name"]}
                  </Typography>
                </User>
              </Fragment>
            )}
          </Ticket>
        ))}
      </Container>
    </Column>
  );
};
