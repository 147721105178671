import {FC} from "react";
import {useTheme} from "styled-components";
import {useNavigate} from "react-router-dom";
import {SvgIconComponent} from "@mui/icons-material";
import {ChevronLeft, IconProps} from "react-feather";
import {IconButton, IconButtonProps} from "components/@button";
import {
  Row,
  Options,
  Typography,
  OptionsProps,
  TypographyProps,
} from "components/@system";

type Action =
  | {type: "icon-button"; props: IconButtonProps}
  | {
      type: "option";
      props: Omit<OptionsProps, "children">;
      iconButton: IconButtonProps;
    };

interface HeaderProps extends TypographyProps {
  goBack?: boolean;
  actions?: Action[];
  icon?: FC<IconProps> | SvgIconComponent;
}

export const Header: React.FC<HeaderProps> = ({
  icon: Icon,
  actions = [],
  goBack = false,
  ...typographyProps
}) => {
  const push = useNavigate();
  const {white, white01} = useTheme();

  return (
    <Row>
      {goBack && (
        <IconButton
          size={38}
          style={{marginRight: 5}}
          onClick={() => push(-1)}
          icon={<ChevronLeft color={white} />}
        />
      )}
      {Icon && (
        <Icon
          size={20}
          strokeWidth={2}
          style={{fontSize: 20, strokeWidth: 2, color: white}}
        />
      )}
      <Typography
        fontSize={19}
        fontFamily="Poppins-SemiBold"
        {...typographyProps}
      />
      <Row marginLeft={5}>
        {actions.map((action, key) =>
          action["type"] === "icon-button" ? (
            <IconButton
              key={key}
              backgroundColor={white01}
              {...action["props"]}
              size={38}
              toolTipPosition="down"
              style={{marginLeft: 5, ...action["props"]["style"]}}
            />
          ) : (
            <Options key={key} {...action["props"]}>
              {({toggle}) => (
                <IconButton
                  style={{marginLeft: 5}}
                  onClick={toggle}
                  {...action["iconButton"]}
                  backgroundColor={white01}
                  size={38}
                />
              )}
            </Options>
          )
        )}
      </Row>
    </Row>
  );
};
