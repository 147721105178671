import {get, getById} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";
import {supportTicketInitialState} from "@redux/initial-states";

export const supportSlice = createSlice({
  name: "support",
  initialState,
  reducers: {
    resetSupportTicket: function (state) {
      state["ticket"] = supportTicketInitialState;
      state["request"]["ticket"] = setRequestStatus("initial");
    },
  },
  extraReducers: function (builder) {
    builder.addCase(get.pending, function (state) {
      state["request"]["tickets"] = setRequestStatus("initial");
    });
    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["tickets"] = data;
      state["request"]["tickets"] = setRequestStatus("success");
    });
    builder.addCase(get.rejected, function (state) {
      state["request"]["tickets"] = setRequestStatus("error");
    });

    builder.addCase(getById.pending, function (state) {
      state["request"]["ticket"] = setRequestStatus("initial");
    });
    builder.addCase(getById.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["ticket"] = data;
      state["request"]["ticket"] = setRequestStatus("success");
    });
    builder.addCase(getById.rejected, function (state) {
      state["request"]["ticket"] = setRequestStatus("error");
    });
  },
});

export const {resetSupportTicket} = supportSlice["actions"];
export const {reducer: support} = supportSlice;
