import {useAppSelector} from "hooks";
import {Fragment} from "react/jsx-runtime";
import {Route, Routes} from "react-router-dom";
import {Layout as AuthLayout} from "components";
import {authRoutes, welcomeRoutes} from "./config";

interface NavigationProps {}

export const Navigation: React.FC<NavigationProps> = () => {
  const {isLogged} = useAppSelector("auth");
  const Layout = isLogged ? AuthLayout : Fragment;
  const routes = isLogged ? authRoutes : welcomeRoutes;
  return (
    <Layout>
      <Routes
        children={routes.map(({path, element}, key) => (
          <Route key={key} path={path} element={element} />
        ))}
      />
    </Layout>
  );
};
