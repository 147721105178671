import {Column, Typography} from "components";
import {Container, Grid, Video} from "./styled";
import {TestimonialModel} from "interfaces";
import moment from "moment";

interface WatchTestimonialProps {
  testimonial: TestimonialModel;
}

export const WatchTestimonial: React.FC<WatchTestimonialProps> = ({
  testimonial,
}) => {
  const {media, message, created_at} = testimonial;
  return (
    <Container>
      <Grid>
        <Video controls autoPlay src={media[0]} />
        <Column gap={5}>
          <Typography>{moment(created_at).fromNow()}</Typography>
          <Typography fontFamily="Poppins-Medium">{message}</Typography>
        </Column>
      </Grid>
    </Container>
  );
};
