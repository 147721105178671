import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";
import {
  getCities,
  getStates,
  removeCity,
  createCity,
  updateCity,
  createState,
  removeState,
  updateState,
  getCountries,
  createCountry,
  removeCountry,
  updateCountry,
} from "./thunks";

export const locationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(getCountries.pending, function (state) {
      state["request"]["countries"] = setRequestStatus("initial");
    });
    builder.addCase(getCountries.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["countries"] = data;
      state["request"]["countries"] = setRequestStatus("success");
    });
    builder.addCase(getCountries.rejected, function (state) {
      state["request"]["countries"] = setRequestStatus("error");
    });

    builder.addCase(getStates.pending, function (state) {
      state["request"]["states"] = setRequestStatus("initial");
    });
    builder.addCase(getStates.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["states"] = data;
      state["request"]["states"] = setRequestStatus("success");
    });
    builder.addCase(getStates.rejected, function (state) {
      state["request"]["countries"] = setRequestStatus("error");
    });

    builder.addCase(getCities.pending, function (state) {
      state["request"]["cities"] = setRequestStatus("initial");
    });
    builder.addCase(getCities.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["cities"] = data;
      state["request"]["cities"] = setRequestStatus("success");
    });
    builder.addCase(getCities.rejected, function (state) {
      state["request"]["cities"] = setRequestStatus("error");
    });

    builder.addCase(createCountry.fulfilled, function (state, action) {
      const newCountry = action.payload;
      state["countries"] = [...state["countries"], newCountry];
    });
    builder.addCase(createState.fulfilled, function (state, action) {
      const newState = action.payload;
      state["states"] = [...state["states"], newState];
    });
    builder.addCase(createCity.fulfilled, function (state, action) {
      const newCity = action.payload;
      state["cities"] = [...state["cities"], newCity];
    });

    builder.addCase(updateCountry.fulfilled, function (state, action) {
      const data = action["payload"];
      const index = state["countries"].findIndex(
        ({_id}) => _id === data["_id"]
      );
      if (index === -1) return;
      state["countries"][index] = data;
    });
    builder.addCase(updateState.fulfilled, function (state, action) {
      const data = action["payload"];
      const index = state["states"].findIndex(({_id}) => _id === data["_id"]);
      if (index === -1) return;
      state["states"][index] = data;
    });
    builder.addCase(updateCity.fulfilled, function (state, action) {
      const data = action["payload"];
      const index = state["cities"].findIndex(({_id}) => _id === data["_id"]);
      if (index === -1) return;
      state["cities"][index] = data;
    });

    builder.addCase(removeCountry.fulfilled, function (state, action) {
      const removedId = action["payload"];
      state["countries"] = state["countries"].filter(
        ({_id}) => _id !== removedId
      );
    });
    builder.addCase(removeState.fulfilled, function (state, action) {
      const removedId = action["payload"];
      state["states"] = state["states"].filter(({_id}) => _id !== removedId);
    });
    builder.addCase(removeCity.fulfilled, function (state, action) {
      const removedId = action["payload"];
      state["cities"] = state["cities"].filter(({_id}) => _id !== removedId);
    });
  },
});

export const {reducer: location} = locationSlice;
