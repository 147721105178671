import moment from "moment";
import {formatDate} from "utils";
import {InfoRow} from "./styled";
import {LotteryModel} from "interfaces";
import {useTheme} from "styled-components";
import {useAppDispatch, useAppSelector} from "hooks";
import {CornerDownRight, DollarSign} from "react-feather";
import {Column, HasAccess, Input, Line, Status, Typography} from "components";
import {update} from "@redux/slices/lottery/thunks";

interface InfoProps {
  lottery: LotteryModel;
}

export const Info: React.FC<InfoProps> = ({lottery}) => {
  const {white} = useTheme();
  const dispatch = useAppDispatch();
  const {isSuperAdmin} = useAppSelector("auth");
  const {status, awards, end_date, createdAt, start_date, expenses} = lottery;

  return (
    <Column width="100%">
      <Typography fontFamily="Poppins-SemiBold" fontSize={18}>
        Información general
      </Typography>
      <Column width="100%" gap={5}>
        <InfoRow>
          <CornerDownRight size={20} color={white} />
          <Typography>Estado del sorteo</Typography>
          <Status status={status} />
        </InfoRow>
        <InfoRow>
          <CornerDownRight size={20} color={white} />
          <Typography>Cantidad de premios</Typography>
          <Typography fontSize={17} fontFamily="Poppins-Medium">
            {awards["length"]} premios
          </Typography>
        </InfoRow>
        <InfoRow>
          <CornerDownRight size={20} color={white} />
          <Typography>Fecha de creación</Typography>
          <Typography fontSize={17} fontFamily="Poppins-Medium">
            {moment(createdAt).format("DD/MM/YYYY")}
          </Typography>
        </InfoRow>
        <InfoRow>
          <CornerDownRight size={20} color={white} />
          <Typography>Fecha de inicio</Typography>
          <Typography fontSize={17} fontFamily="Poppins-Medium">
            {moment(formatDate(start_date)).format("DD/MM/YYYY hh:mm:ss")}
          </Typography>
        </InfoRow>
        <InfoRow>
          <CornerDownRight size={20} color={white} />
          <Typography>Fecha de cierre</Typography>
          <Typography fontSize={17} fontFamily="Poppins-Medium">
            {moment(formatDate(end_date)).format("DD/MM/YYYY hh:mm:ss")}
          </Typography>
        </InfoRow>
        <HasAccess only={isSuperAdmin}>
          <Line />
          <InfoRow style={{alignItems: "center"}}>
            <CornerDownRight size={20} color={white} />
            <Typography>Inversión realizada</Typography>
            <Input
              min={1}
              type="number"
              icon={DollarSign}
              placeholder="USD"
              defaultValue={expenses}
              containerStyle={{marginBottom: 0}}
              onBlur={({target}) => {
                const newExpense = Number(target["value"]);
                if (newExpense !== expenses) {
                  dispatch(update({...lottery, expenses: newExpense}));
                }
              }}
            />
          </InfoRow>
        </HasAccess>
      </Column>
    </Column>
  );
};
