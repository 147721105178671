import {colors} from "styles";
import {getConfig} from "utils";
import {Container, Dot} from "./styled";
import {useTheme} from "styled-components";
import {ChevronDown, LogOut, Plus} from "react-feather";
import {logOut, toggleFullScreenLoader} from "@redux/slices";
import {useAppDispatch, useAppSelector, useModal, useToggleLive} from "hooks";
import {
  Row,
  Toggle,
  Options,
  Confirm,
  HasAccess,
  IconButton,
  NewLottery,
  Typography,
  ChangeToLive,
} from "components";

interface TopBarProps {}

export const TopBar: React.FC<TopBarProps> = () => {
  const dispatch = useAppDispatch();
  const {toggleLive} = useToggleLive();
  const {openModal, closeModal} = useModal();
  const {white, heart, secondary} = useTheme();
  const {configs} = useAppSelector("config");
  const config = getConfig(configs, "on_live");
  const {user, isSuperAdmin} = useAppSelector("auth");
  const {value} = config;

  return (
    <Container>
      <HasAccess only={isSuperAdmin}>
        <Row>
          <Typography fontFamily="Poppins-Medium">En VIVO</Typography>
          <Toggle
            on={value === "1"}
            switchStyle={{
              backgroundColor:
                value === "1" ? colors["secondary"] : colors["heart"],
            }}
            onToggle={() => {
              const nextValue = value === "0" ? "1" : "0";
              if (nextValue === "0") {
                openModal(
                  <Confirm
                    title="¿Seguro que deseas desactivar el EN VIVO?"
                    message="Esta acción afectará la vista de los usuarios en la aplicación"
                    actions={[
                      {
                        label: "Sí, desactivar",
                        loaderId: "remove-config",
                        style: {backgroundColor: heart},
                        onClick: () => toggleLive(nextValue),
                      },
                      {
                        label: "Cancelar",
                        onClick: () => closeModal(),
                        style: {backgroundColor: "#bcbcbcec"},
                      },
                    ]}
                  />
                );
              } else {
                openModal(<ChangeToLive nextOnLiveValue={nextValue} />);
              }
            }}
          />
        </Row>
        <Options
          listStyle={{width: "200px", right: 0, top: "2.5rem"}}
          options={[
            {
              label: "Crear sorteo",
              icon: <Plus size={20} color={white} />,
              onClick: () => openModal(<NewLottery />),
            },
          ]}
        >
          {({toggle}) => (
            <IconButton
              size={38}
              onClick={toggle}
              backgroundColor={secondary}
              icon={<Plus color={white} />}
            />
          )}
        </Options>
      </HasAccess>
      <Dot />
      <Options
        listStyle={{width: "200px", right: 0, top: "2.5rem"}}
        options={[
          {
            label: "Cerrar sesión",
            typographyProps: {color: colors["heart"]},
            icon: <LogOut size={20} color={colors["heart"]} />,
            onClick: () => {
              dispatch(toggleFullScreenLoader(true));
              setTimeout(() => {
                dispatch(logOut());
              }, 500);
            },
          },
        ]}
      >
        {({toggle}) => (
          <Row gap={10} cursor="pointer" onClick={toggle}>
            <Typography color={white}>{user["name"]}, Administrador</Typography>
            <ChevronDown color={white} />
          </Row>
        )}
      </Options>
    </Container>
  );
};
