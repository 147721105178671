import {CustomAccordionItem} from ".";
import styled from "styled-components";

export const AccordionItem = styled(CustomAccordionItem)`
  .szh-accordion__item {
    &-btn {
      margin: 0;
      display: flex;
      width: 100%;
      cursor: pointer;
      padding: 1rem 0.8rem;
      font-size: 1rem;
      text-align: left;
      position: relative;
      align-items: center;
      background-color: unset;
      border: none;
      border-radius: 5px;
      &:hover {
        background-color: ${({theme}) => theme["hover"]};
      }
    }

    &-content {
      transition: height 0.25s cubic-bezier(0, 0, 0, 1);
      color: ${(props) => props["theme"]["black"]};
    }

    &-panel {
      padding: 0.8rem 0.5rem;
    }
  }

  .chevron-down {
    margin-left: auto;
    transition: transform 0.25s cubic-bezier(0, 0, 0, 1);
    color: ${(props) => props["theme"]["white"]};
  }

  .hash {
    color: ${(props) => props["theme"]["primary"]};
  }

  &.szh-accordion__item--expanded {
    .szh-accordion__item-btn {
      background-color: ${({theme}) => theme["white01"]};
    }
    .chevron-down {
      transform: rotate(180deg);
    }
  }

  border-bottom: 0.3px solid ${({theme}) => theme["line"]};

  &:last-child {
    border-bottom: unset;
  }
`;
