import {Children} from "interfaces";
import {Sidebar, TopBar} from "./lib";
import {Container, Main, Body} from "./styled";

interface LayoutProps extends Children {}

export const Layout: React.FC<LayoutProps> = ({children}) => {
  return (
    <Container>
      <Sidebar />
      <Main>
        <TopBar />
        <Body>{children}</Body>
      </Main>
    </Container>
  );
};
