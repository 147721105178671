import moment from "moment";
import {useEffect} from "react";
import {useTheme} from "styled-components";
import {ThumbsUp, Video} from "react-feather";
import {get} from "@redux/slices/testimonials/thunks";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {
  Table,
  Header,
  Column,
  Skeleton,
  IconButton,
  Typography,
  ComponentLoader,
  WatchTestimonial,
} from "components";

interface TestimonialsProps {}

export const Testimonials: React.FC<TestimonialsProps> = () => {
  const {openModal} = useModal();
  const dispatch = useAppDispatch();
  const {container, white, blackWhite} = useTheme();
  const {testimonials, request} = useAppSelector("testimonials");

  useEffect(() => {
    dispatch(get());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Column>
      <Header children="Testimonios" icon={ThumbsUp} />
      <ComponentLoader
        requestStatus={request["testimonials"]}
        emptyUI={{
          data: testimonials,
          style: {width: "100%"},
          message: "No hay testimonios súbidos aún",
          icon: <ThumbsUp size={40} color={blackWhite} />,
        }}
        skeleton={
          <Column width="100%">
            <Skeleton width={300} height={50} />
            <Column width="100%">
              <Skeleton width="70%" height={40} />
              <Column gap={1} width="100%">
                <Skeleton count={5} width="100%" height={80} />
              </Column>
            </Column>
          </Column>
        }
      >
        {testimonials.map(({lottery, awards}) => (
          <Column marginBottom={15} key={lottery["_id"]}>
            <Typography fontFamily="Poppins-Medium" fontSize={16}>
              #{lottery?.["number"]} - {lottery?.["title"]}
            </Typography>
            <Table
              data={awards}
              columns="120px 350px 200px 140px"
              head={[
                {label: "Fecha"},
                {label: "Mensaje"},
                {label: "Premio"},
                {label: "Acciones"},
              ]}
            >
              {({title, number}) => (
                <Table.Tr key={title} backgroundColor={container}>
                  <Table.Td>
                    <Typography>
                      {moment(number!["testimonial"]["createdAt"]).format(
                        "DD/MM/YYYY"
                      )}
                    </Typography>
                  </Table.Td>
                  <Table.Td>
                    <Typography>{number!["testimonial"]["message"]}</Typography>
                  </Table.Td>
                  <Table.Td>
                    <Typography>{title}</Typography>
                  </Table.Td>
                  <Table.Td>
                    <IconButton
                      icon={<Video color={white} />}
                      toolTip="Ver testimonio"
                      onClick={() =>
                        openModal(
                          <WatchTestimonial
                            testimonial={number!["testimonial"]}
                          />
                        )
                      }
                    />
                  </Table.Td>
                </Table.Tr>
              )}
            </Table>
          </Column>
        ))}
      </ComponentLoader>
    </Column>
  );
};
