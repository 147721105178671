import {Children} from "interfaces";
import {useNavigate} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "hooks";
import {Fragment, useEffect, useLayoutEffect} from "react";
import {showAlert, toggleFullScreenLoader} from "@redux/slices";

interface SuperAdminRouteProps extends Children {}

export const SuperAdminRoute: React.FC<SuperAdminRouteProps> = ({children}) => {
  const push = useNavigate();
  const dispatch = useAppDispatch();
  const {isSuperAdmin} = useAppSelector("auth");

  useEffect(() => {
    if (isSuperAdmin) {
      dispatch(toggleFullScreenLoader(false));
    } else {
      push("/");
      setTimeout(() => {
        dispatch(toggleFullScreenLoader(false));
        dispatch(
          showAlert({
            type: "error",
            title: "No tienes permisos",
            message: "Para acceder a esta pantalla necesitas ser Super Admin",
          })
        );
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuperAdmin]);
  useLayoutEffect(() => {
    dispatch(toggleFullScreenLoader(true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return <Fragment>{children}</Fragment>;
};
