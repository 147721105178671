import moment from "moment";
import {useEffect} from "react";
import {Container} from "./styled";
import {useTheme} from "styled-components";
import {Refresh} from "@mui/icons-material";
import {openMultimedia} from "@redux/slices";
import {useAppDispatch, useAppSelector, useModal} from "hooks";
import {initialState} from "@redux/slices/payments/initial-state";
import {changePaymentStatus, get} from "@redux/slices/payments/thunks";
import {AlertCircle, Check, Clock, DollarSign, Info, X} from "react-feather";
import {
  Row,
  Chip,
  Table,
  Image,
  Header,
  Column,
  Confirm,
  Skeleton,
  Dropdown,
  Typography,
  IconButton,
  ComponentLoader,
  SuperAdminRoute,
} from "components";

interface PaymentsProps {}

export const Payments: React.FC<PaymentsProps> = () => {
  const dispatch = useAppDispatch();
  const {openModal, closeModal} = useModal();
  const {payments, request, filter} = useAppSelector("payments");
  const {container, black03, heart, blackWhite, secondary, white} = useTheme();

  useEffect(() => {
    dispatch(get(initialState["filter"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SuperAdminRoute>
      <Column>
        <Header
          icon={DollarSign}
          children="Pagos"
          actions={[
            {
              type: "icon-button",
              props: {
                toolTip: "Actualizar",
                onClick: () => dispatch(get(filter)),
                icon: <Refresh style={{color: blackWhite}} />,
              },
            },
          ]}
        />
        <Row>
          <Dropdown
            style={{width: "310px"}}
            defaultValue={filter["method"]}
            onSelect={({value}) => {
              dispatch(get({...filter, method: String(value)}));
            }}
            options={[
              {icon: DollarSign, label: "Todos los métodos", value: ""},
              {icon: DollarSign, label: "Pago móvil", value: "1"},
              {icon: DollarSign, label: "Transferencia Bancaria", value: "0"},
              {icon: DollarSign, label: "Tarjeta de crédito", value: "5"},
              {icon: DollarSign, label: "Cupones", value: "4"},
              {icon: DollarSign, label: "Binance", value: "2"},
              {icon: DollarSign, label: "Zelle", value: "3"},
            ]}
          />
          <Dropdown
            style={{width: "310px"}}
            defaultValue={filter["status"]}
            onSelect={({value}) => {
              dispatch(get({...filter, status: String(value)}));
            }}
            options={[
              {icon: Info, label: "Todos los estados", value: ""},
              {icon: Clock, label: "Pendiente", value: "0"},
              {icon: Check, label: "Aprobado", value: "1"},
              {icon: X, label: "Rechazado", value: "2"},
            ]}
          />
        </Row>
        <ComponentLoader
          requestStatus={request["payments"]}
          skeleton={
            <Column width="100%">
              <Column width="100%">
                <Skeleton width="70%" height={40} />
                <Column gap={1} width="100%">
                  <Skeleton count={8} width="100%" height={65} />
                </Column>
              </Column>
            </Column>
          }
        >
          <Container>
            <Table
              data={payments}
              columns="180px 85px 200px 130px 170px 150px 220px 80px 180px 130px"
              emptyUI={{
                message: "No hay pagos realizados aún",
                icon: <DollarSign color={blackWhite} size={40} />,
              }}
              head={[
                {label: "Usuario"},
                {label: "Monto"},
                {label: "Fecha"},
                {label: "Estatus"},
                {label: "Sorteo"},
                {label: "Método"},
                {label: "Ref."},
                {label: "Evid."},
                {label: "Tickets"},
                {label: "Acciones"},
              ]}
            >
              {(payment) => {
                const {
                  _id,
                  code,
                  status,
                  method,
                  amount,
                  picture,
                  fullname,
                  createdAt,
                  lotteryName,
                  methodString,
                  statusString,
                  number: tickets,
                } = payment;
                const evidence = picture[0];
                const isApproved = status === 1;
                const isDeclined = status === 2;
                const isCreditMethod = method === 4;
                const StatusIcon = isApproved ? Check : X;
                const ButtonIcon = isApproved ? AlertCircle : Check;
                return (
                  <Table.Tr key={_id} backgroundColor={container}>
                    <Table.Td gap={6} overflow="hidden">
                      <Typography fontSize={16} className="line-clamp-1">
                        {fullname}
                      </Typography>
                    </Table.Td>
                    <Table.Td>
                      <Typography fontFamily="Poppins-Medium" fontSize={16}>
                        ${amount.toFixed(2)}
                      </Typography>
                    </Table.Td>
                    <Table.Td>
                      <Typography fontFamily="Poppins-Light" fontSize={16}>
                        {moment(createdAt).format("DD/MM/YYYY hh:mm:ss")}
                      </Typography>
                    </Table.Td>
                    <Table.Td>
                      <Chip
                        backgroundColor={
                          isApproved ? secondary : isDeclined ? heart : black03
                        }
                      >
                        <Typography
                          fontSize={14}
                          color={white}
                          fontFamily="Poppins-Medium"
                        >
                          {statusString}
                        </Typography>
                      </Chip>
                    </Table.Td>
                    <Table.Td>
                      <Typography
                        fontSize={16}
                        className="line-clamp-1"
                        fontFamily="Poppins-Light"
                      >
                        {lotteryName}
                      </Typography>
                    </Table.Td>
                    <Table.Td>
                      <Typography
                        fontSize={16}
                        className="line-clamp-1"
                        fontFamily="Poppins-SemiBold"
                      >
                        {methodString}
                      </Typography>
                    </Table.Td>
                    <Table.Td>
                      <Typography
                        fontSize={16}
                        className="line-clamp-1"
                        fontFamily="Poppins-Light"
                      >
                        {code}
                      </Typography>
                    </Table.Td>
                    <Table.Td>
                      <Image
                        objectFit="contain"
                        size={isCreditMethod ? 45 : 50}
                        src={
                          isCreditMethod
                            ? "/images/tickets.png"
                            : evidence
                            ? evidence
                            : "/images/logo.png"
                        }
                        onClick={() =>
                          evidence
                            ? dispatch(openMultimedia([evidence]))
                            : undefined
                        }
                        cursor={
                          isCreditMethod || !evidence ? "default" : "pointer"
                        }
                      />
                    </Table.Td>
                    <Table.Td>
                      <Typography fontSize={16} fontFamily="Poppins-Light">
                        {tickets.map(
                          (ticket, key, arr) =>
                            `${ticket}${
                              arr["length"] > 1 && key + 1 !== arr["length"]
                                ? ",  "
                                : ""
                            }`
                        )}
                      </Typography>
                    </Table.Td>
                    <Table.Td gap={6}>
                      {status === 0 ? (
                        <Row>
                          <IconButton
                            toolTip="Aprobar pago"
                            backgroundColor={secondary}
                            icon={<ButtonIcon color={white} />}
                            toolTipStyle={{backgroundColor: secondary}}
                            onClick={() => {
                              openModal(
                                <Confirm
                                  title="¿Estás seguro?"
                                  message={`El estado del pago de ${fullname} pasará a ser aprobado por ti. Con lo cual convalidas que el dinero ha llegado con éxito`}
                                  actions={[
                                    {
                                      label: "Aprobar",
                                      loaderId: "change-payment-status",
                                      leftIcon: <ButtonIcon color={white} />,
                                      style: {backgroundColor: secondary},
                                      onClick: () => {
                                        dispatch(
                                          changePaymentStatus({
                                            ...payment,
                                            status: 1,
                                          })
                                        );
                                      },
                                    },
                                    {
                                      label: "Cancelar",
                                      onClick: () => closeModal(),
                                      style: {backgroundColor: "#bcbcbcec"},
                                    },
                                  ]}
                                />
                              );
                            }}
                          />
                          <IconButton
                            toolTip="Rechazar pago"
                            backgroundColor={heart}
                            icon={<X color={white} />}
                            toolTipStyle={{backgroundColor: heart}}
                            onClick={() => {
                              openModal(
                                <Confirm
                                  title="¿Estás seguro?"
                                  message={`El estado del pago de ${fullname} pasará a ser rechazado por ti. Con lo cual convalidas que el dinero NO llegó con éxito`}
                                  actions={[
                                    {
                                      label: "Rechazar",
                                      loaderId: "change-payment-status",
                                      leftIcon: <X color={white} />,
                                      style: {backgroundColor: heart},
                                      onClick: () => {
                                        dispatch(
                                          changePaymentStatus({
                                            ...payment,
                                            status: 2,
                                          })
                                        );
                                      },
                                    },
                                    {
                                      label: "Cancelar",
                                      onClick: () => closeModal(),
                                      style: {backgroundColor: "#bcbcbcec"},
                                    },
                                  ]}
                                />
                              );
                            }}
                          />
                        </Row>
                      ) : (
                        <IconButton
                          disabled
                          icon={
                            <StatusIcon
                              color={isApproved ? secondary : heart}
                            />
                          }
                          toolTipStyle={{
                            backgroundColor: isApproved ? secondary : heart,
                          }}
                          toolTip={
                            status === 1 ? "Pago Aprobado" : "Pago Rechazado"
                          }
                        />
                      )}
                    </Table.Td>
                  </Table.Tr>
                );
              }}
            </Table>
          </Container>
        </ComponentLoader>
      </Column>
    </SuperAdminRoute>
  );
};
