import {get, remove} from "./thunks";
import {createSlice} from "@reduxjs/toolkit";
import {initialState} from "./initial-state";
import {setRequestStatus} from "@redux/request-status";

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(get.fulfilled, function (state, action) {
      const {data} = action.payload;
      state["users"] = data["data"];
      state["request"]["users"] = setRequestStatus("success");
    });

    builder.addCase(get.rejected, function (state) {
      state["request"]["users"] = setRequestStatus("error");
    });

    builder.addCase(remove.fulfilled, function (state, action) {
      const userId = action["payload"];
      state["users"] = state["users"].filter((user) => user["_id"] !== userId);
    });
  },
});

export const {reducer: users} = usersSlice;
