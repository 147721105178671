import {Grid} from "./lib/Awards/styled";
import {Container} from "./lib/Statistics/styled";
import {Column, Row, Skeleton as UISkeleton} from "components";
import {Container as TicketsWrapper} from "./lib/Tickets/styled";

export const Skeleton = () => (
  <Column gap={15} maxWidth="1350px" margin="0 auto">
    <Row marginBottom={10}>
      <UISkeleton width={45} height={45} borderRadius={100} />
      <UISkeleton width={300} height={45} />
    </Row>
    <Column width="100%">
      <UISkeleton width={150} height={35} />
      <Container>
        <UISkeleton count={5} height={150} />
      </Container>
    </Column>
    <Column width="100%">
      <Row>
        <UISkeleton width={150} height={35} />
      </Row>
      <Grid>
        <UISkeleton count={2} height={550} minWidth="500px" maxWidth="500px" />
      </Grid>
    </Column>
    <Column width="100%">
      <UISkeleton width={150} height={35} />
      <UISkeleton width="100%" height={200} />
    </Column>
    <Column width="100%">
      <UISkeleton width={150} height={35} />
      <TicketsWrapper style={{backgroundColor: "unset"}}>
        <UISkeleton count={24} height={70} />
      </TicketsWrapper>
    </Column>
  </Column>
);
