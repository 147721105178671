import * as Yup from "yup";
import {CreateCityPayload} from "interfaces";

export const initialValues: CreateCityPayload = {
  name: "",
  stateId: "",
};

export const citySchema = Yup.object().shape({
  name: Yup.string().required("Este campo es obligatorio"),
  stateId: Yup.string().required("Este campo es obligatorio"),
});
