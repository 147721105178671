import styled from "styled-components";

export const Container = styled.div`
  gap: 0.5rem;
  display: flex;
  flex-wrap: wrap;
`;

export const Media = styled.div`
  width: 90px;
  height: 90px;
  display: flex;
  padding: 0.3rem;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: ${({theme}) => theme["hover"]};
  border: 1px dashed ${({theme}) => theme["black01"]};
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Video = styled.video`
  width: 100%;
  height: 100%;
`;
